<template>
  <v-expansion-panels
    model-value="1"
    class="mb-6"
    v-if="role >= 0 && role <= 2 && !isexit"
  >
    <v-expansion-panel value="1">
      <v-expansion-panel-title class="px-4">
        <div class="d-flex justify-space-between w-75">
          <div>
            <v-icon>mdi-tune</v-icon>
            <span class="mx-1">{{ $t("globals.select-criteria") }}</span>
          </div>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol cols="12" md="3" sm="6" v-if="role == 0 || role == 1">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
            ></VAutocomplete>
          </VCol>
          <v-col cols="6" md="3">
            <v-autocomplete
              color="indigo"
              v-model="school_type"
              density="compact"
              :clearable="false"
              item-title="type"
              item-value="id"
              prepend-inner-icon="mdi-home"
              :items="typeList"
              :label="$t('summer.summer_type')"
              @input="v$.school_type.$touch"
              :error-messages="v$.school_type.$errors.map((e) => e.$message)"
            />
          </v-col>
          <v-col cols="6" sm="2" class="align-center mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100"
              color="indigo"
              :loading="loading"
              @click="fetchData()"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{reportTitle}}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="text-end mt-2" id="tableHead">
      <v-btn-group>
        <v-btn density="compact" class="pa-0" @click="exportToExcel">
          <span>{{ $t("export.export") }} </span>
          <v-icon size="large" color="green-darken-2" end
            >mdi-file-excel</v-icon
          >
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("export.excel") }}</small>
          </v-tooltip>
        </v-btn>
        <v-btn
          v-bind="props"
          density="compact"
          style="min-width: 25px"
          @click="printTable"
        >
          <v-icon color="icon-color">mdi-printer</v-icon>
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("globals.print-file") }}</small>
          </v-tooltip>
        </v-btn>
        <v-btn
          density="compact"
          @click="(isexit = !isexit), (statistics = []),(header_1 = []),(headers = [])"
          v-if="role >= 0 && role <= 2"
        >
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </v-btn-group>
    </v-card-title>
    <div class=" px-1" v-if="statistics?.length > 0">
      <div class="overflow-y-auto mt-2 ">
        <table
          class="rounded text-center report-t "
          density="compact"
          border="1"
          id="data-table"
        >
          <thead>
            <tr class="text-center">
              <th
                v-for="(head, index) in header_1"
                :rowspan="head.rows"
                :key="index"
                :colspan="head?.cols"
              >
                {{ head.header }}
              </th>
            </tr>
            <tr class="text-center">
              <th v-for="(head, index) in headers" :key="index" >
                <small>{{ head.header }}</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in statistics" :key="index">
              <td v-for="head in headers" :key="head" >
                {{ row[head.key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <v-card v-else  class="w-100 pa-3 text-center">
      لم يتم العثور على سجلات مرتبطة بهذه البيانات
    </v-card>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      if (this.role == 2) {
        this.fk_governorate = parseInt(localStorage.getItem("governorate"));
      } else if (this.role == 3) {
        this.fk_governorate = parseInt(localStorage.getItem("governorate"));
        this.fk_directorate = parseInt(localStorage.getItem("directorate"));
        this.isexit = true;
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getDirectorate: "school/getDirectorate",
      getGovernorates: "school/getGovernorates",
    }),
    async exportToExcel() {
      this.$ExportToExcelWithColspan(this.header_1,this.headers,this.statistics,this.reportTitle);
    },
    getGovernorateName(gov_id) {
      return this.governorates?.find((gov) => gov.id == gov_id)?.name_ar;
    },
    getDirectorateName(dir_id) {
      return this.directorates?.find((dir) => dir.id == dir_id)?.name_ar;
    },
    async fetchData() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/summer/final-report`, {
            params: {
              fk_governorate: this.fk_governorate,
              school_type: this.school_type,
            },
          })
          .then((response) => {
            this.headers = [];
            this.loading = false;
            this.statistics = response.data?.data;
            this.header_1 = response.data?.header_1;
            if (response.data?.headers) {
              const keys = Object.keys(response.data.headers);
              keys?.forEach((key) => {
                this.headers.push({
                  header: response.data.headers[key],
                  key: key,
                });
              });
            }
            this.isexit = true;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    async printTable() {
     
      window.print();
    },
  },
  validations() {
    return {
      school_type: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
    };
  },
  data() {
    return {
      // role:localStorage.getItem('role'),
      typeList: [
        { id: 1, type: this.$t("reportpublic.Open") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      v$: useValidate(),
      loading: false,
      headers: [],
      header_1: [],
      fk_governorate: null,
      fk_directorate: null,
      school_type: 1,
      isexit: false,
      statistics: [],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      governorates: (state) => state.school.governorates,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    reportTitle(){
      const title = " التقرير النهائي للدورات الصيفية"
      var str = ''
      if(this.fk_governorate){
        str = ` ${this.$t("globals.for-governorate")} ( ${this.getGovernorateName(this.fk_governorate)}  )`
      }else{
        str = ` ( ${this.$t("globals.for-all-governorates") })`
      }
      if(this.fk_directorate){
        str+=` ${this.$t("globals.for-directorate") } (   ${this.getDirectorateName(this.fk_directorate) } )`
      }else{
        str+=`(  ${this.$t("globals.for-all-directorates") } )`
      }
      return `${title} ${str}`

    },
  },
  components: {
    ReportHeader,
  },
};
</script>
