export default {
  "elevation": {
    "student_elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
    "current_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الحالي"])},
    "next_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف التالي"])},
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير"])},
    "registeration_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة التسجيل"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطالب"])},
    "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الدراسة"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الدرجات"])},
    "elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])}
  },
  "student": {
    "walkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنسحبين"])},
    "constant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستمرين"])}
  },
  "dashboard": {
    "summer-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الدورات الصيفية"])},
    "gender-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الذكور - الأناث"])},
    "teachers-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المدرسين"])},
    "teachers-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب - أساتذة"])},
    "save-as-png": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ كصورة png"])},
    "students-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
    "teachers-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدرسين"])},
    "lectures-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحصص"])},
    "total-income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الدخل"])},
    "subjects-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المواد"])},
    "students-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب"])},
    "walkout-constant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستمرين - المنسحبين "])},
    "presents-absents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور - الغياب"])},
    "presents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور"])},
    "absents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغياب"])}
  },
  "committees": {
    "student_number_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تعدين الحد الاقصى لعدد الطلاب للهذا الفصل"])},
    "add_students_to_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الطلاب الى اللجان"])},
    "add_committee_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الطلاب في اللجنة"])}
  },
  "user": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "profile-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية المتعلقة بحساب المستخدم مع الدور الذي يملكة للتعامل مع النظام"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
    "view-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المستخدمين"])},
    "logout-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد انك تريد تسجيل الخروج من النظام ؟"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب نشط بهذه البيانات"])},
    "user_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المستخدمين"])},
    "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مستخدم "])},
    "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مستخدم "])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاخير"])},
    "full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الكامل"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الايميل"])},
    "superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المستخدم"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
    "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشرف"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكيد كلمة المرور "])},
    "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الالتحاق"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "is_deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل كلمة المرور "])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
    "not-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور غير متطابقة"])},
    "username-unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم هذا موجود بالفعل"])},
    "group-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امكانية الظهور ل"])},
    "confirm-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم تغيير كلمة المرور الى كلمة المرور الافتراضية ؟"])}
  },
  "group": {
    "add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المجموعات"])}
  },
  "mobile": {
    "version-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الاصدار"])}
  },
  "permission": {
    "summeropenemployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموظفين للدورات الصيفية المفتوحة"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصدارات التطبيقات"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
    "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "view_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض كتقرير"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانشطة"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفروع"])},
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المباني"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفوف"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايام"])},
    "mobileversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصدارات تطبيق الموبايل"])},
    "classdivisionhall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة الفصول"])},
    "classdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الفصل"])},
    "classstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
    "classsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجان الاختبارات"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الاتصال"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات"])},
    "discountcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصم"])},
    "distributionofseatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات ارقام الحلوس"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوثائق"])},
    "examschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواعيد الاختبارات"])},
    "examsperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الاختبارات"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة الرسوم"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الرسوم"])},
    "feetype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الرسوم"])},
    "paymentsreciept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فواتير القبض"])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات"])},
    "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعات"])},
    "homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات"])},
    "installmentspayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
    "lectureattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الدروس"])},
    "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الشهرية"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء الامور"])},
    "parentmessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل اولياء الامور"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الحصص"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة"])},
    "schoolweeklyschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الحصص الاسبوعي"])},
    "seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارقام الجلوس"])},
    "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترم"])},
    "settingsstudentcommittees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة اللجان"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
    "studentactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك بالانشطة"])},
    "studentattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب"])},
    "studentdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الطلاب"])},
    "studentfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم الطلاب"])},
    "studenthomework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات الطلاب"])},
    "studentsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الفصلية"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسين"])},
    "teachersubjectclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة المدرسين"])},
    "yearofstudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنوات الدراسية"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
    "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائقين"])},
    "studentbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك بالباص"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباصات"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهور الدراسية"])},
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديرات"])},
    "attendance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التحضير"])},
    "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضر"])},
    "is_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستأذن"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصلاحية"])},
    "activitydifficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين الصعوبات والحلول"])},
    "levelstudentYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "leveldivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسناد شعب للمستويات"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خطط الانشطة"])},
    "studenttalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسناد مواهب للطلاب"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحصائيات"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم الدراسي"])},
    "teachersubjectsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين خطة للمدرس"])},
    "activityoperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنفيذ الانشطة"])},
    "activitysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الانشطة"])},
    "activitytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الانشطة"])},
    "parentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء الامور"])},
    "studentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
    "schoolsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات  الدورة الصيفية"])},
    "contactsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "divisionsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الشعب"])},
    "daysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ايام"])},
    "schedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الحصص الدراسية"])},
    "subjectsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المواد الدراسية"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المستويات"])},
    "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المهارات"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة العامل"])},
    "employee-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاملين"])},
    "employeelevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم المناهج"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الاسابيع"])},
    "marksummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين الدرجات الاسبوعية"])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المواهب"])},
    "initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المبادرات"])},
    "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة زائرين"])},
    "commitee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة لجان"])},
    "commiteemember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين اعضاء للجان"])},
    "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوصيات"])},
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام المناهج للمستويات"])},
    "lecturesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة دروس"])},
    "studentattendancesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب"])},
    "employeeattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير العاملين"])},
    "schoolweeklyschedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الحصص الاسبوعي"])},
    "schoolweeklyactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الانشطة الاسبوعي"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة اجازات"])},
    "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة المشرفة"])},
    "managmenttask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام الادراية"])},
    "supplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلزمات"])},
    "levelstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسناد الطلاب للمستويات"])},
    "activitystatistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الانشطة"])},
    "levelstatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستويات"])},
    "summerschoolstatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الدورات الصيفية  "])},
    "lecturelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سجل الدروس"])},
    "schoolsummeropen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية المفتوحة"])},
    "generalizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعميمات"])},
    "applicationsfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات التطبيق"])}
  },
  "student-attendance": {
    "permission-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاستئذان"])},
    "daily-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحضير اليومي للطلاب على حسب الحصة "])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور والغياب"])},
    "is-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضر ؟"])},
    "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غائب"])},
    "is-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستأذن ؟"])},
    "permission-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدم الأذن"])},
    "permission-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم مقدم الأذن "])},
    "permission-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب الأذن "])},
    "permission-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستئذان"])},
    "permission-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الأذن"])}
  },
  "financial": {
    "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المالية"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك في نظام المالية"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
    "account_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدليل المحاسبي"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحساب العربي"])},
    "account_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحساب الإنجليزي"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رقم الحساب"])},
    "account_type_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الحساب"])},
    "parent_serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الأب"])},
    "account_nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طبيعة الحساب"])},
    "fk_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العملة"])},
    "fk_analyst_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الحساب التحليلي"])},
    "number_of_digits_for_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد التفرعات المتوقعة"])},
    "account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الحساب"])},
    "account_is_sub_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن إستخدام حساب فرعي كحساب رئيسي"])},
    "financial_period_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة المحاسبية"])},
    "financial_periods_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترات المحاسبية"])},
    "financial_year_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الفترة"])},
    "financial_year_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الفترة"])},
    "periodicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع الفترات"])},
    "annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنوي"])},
    "semi-annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نصف سنوي"])},
    "every_4_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل أربعة أشهر"])},
    "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربع سنوي"])},
    "every_2_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل شهرين"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهري"])},
    "period_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفترة العربي"])},
    "period_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفترة الإنجليزي"])},
    "add_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فترة"])},
    "add_financial_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فترة محاسبية"])},
    "edit_financial_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل فترة محاسبية"])},
    "period_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إضافة الفترة بنجاح"])},
    "financial_period_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم إضافة الفترة يمنع تقاطع الفترات يجب ملئ جميع الحقول !"])},
    "not_deleted_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب مستخدم, لا يمكن حذفه !"])},
    "not_updated_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب مستخدم, لا يمكن تعديله !"])},
    "account_not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بجب إختيار حساب لحذفه !"])},
    "account_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إضافة الحساب بنجاح"])},
    "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الحساب بنجاح"])},
    "account_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الحساب بنجاح"])},
    "box_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الصناديق"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالعربي"])},
    "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالانجليزي"])},
    "box_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الصندوق"])},
    "catch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبض"])},
    "spend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صرف"])},
    "main_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الرئيسي"])},
    "box_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الصناديق"])},
    "add_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة صندوق"])},
    "box_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الصندوق"])},
    "bank_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات البنوك"])},
    "add_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة بنك"])},
    "bank_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البنك"])},
    "intermedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيط"])},
    "real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقيقي"])},
    "currencies_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات العملات"])},
    "add_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عملة"])},
    "currency_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز العملة"])},
    "currency_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فكة العملة"])},
    "currency_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العملة"])},
    "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محلي"])},
    "foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجنبي"])},
    "curr_exchange_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الصرف"])},
    "curr_highest_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلى سعر للصرف"])},
    "curr_lowest_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقل سعر للصرف"])},
    "must_be_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون اقل من اعلى سعر للصرف"])},
    "must_be_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون اعلى من اقل سعر للصرف"])},
    "local_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد بالفعل عملة محلية"])},
    "messing_exchange_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل حقول اسعار الصرف"])},
    "currency_is_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة مستخدمة في النظام"])},
    "currency_is_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف العملة"])},
    "currency_is_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة العملة"])},
    "exchange_data_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث سعر الصرف"])},
    "student_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات حسابات الطلاب"])},
    "add_student_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة حساب طالب"])},
    "daily_operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيود يومية"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سندات"])},
    "target_operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية المستهدفة"])},
    "doctype_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات انواع الوثائق"])},
    "doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوثيقة"])},
    "add_doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نوع وثيقة"])},
    "doctype_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل نوع الوثيقة"])},
    "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل البنك"])},
    "student_account_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل حساب الطالب"])},
    "added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الاضافة بنجاح"])},
    "updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التعديل بنجاح"])},
    "deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف بنجاح"])},
    "not_deleted_doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوثيقة مستخدم ولا يمكن حذفه"])},
    "not_updated_doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوثيقة مستخدم ولا يمكن تعديله"])},
    "receipt_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سند صرف"])},
    "catch_receipt_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سند قبض"])},
    "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدفع"])},
    "box_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الصندوق"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب البنك"])},
    "receiver_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب المستلم"])},
    "analyst_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب التحليلي"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقدا"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شيك"])},
    "check_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الشيك"])},
    "payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
    "written_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ كتابة"])},
    "collect_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستحقاق"])},
    "high_exchange_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير, لقد تجاوزت الحد الاعلى للصرف"])},
    "low_exchange_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير, لقد تجاوزت الحد الادنى للصرف"])},
    "date_limit_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن ان يكون تاريخ الاستحقاق قبل تاريخ اليوم"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
    "journal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيد اليومي"])},
    "journal_total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي مبلغ القيد"])},
    "journal_reference_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم مرجع القيد"])},
    "add_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة سجل"])},
    "remove_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف سجل"])},
    "statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيان"])},
    "debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدين"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائن"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
    "f_debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدين اجنبي"])},
    "f_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائن اجنبي"])},
    "debit_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المدين"])},
    "credit_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الدائن"])},
    "journal_data_messing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات القيد ناقصة"])},
    "invalid_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة غير صالحة"])},
    "foreign_debit_local_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن استخدام مدين اجنبي بعملة محلية"])},
    "exchange_price_messing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الصرف غير موجود"])},
    "foreign_debit_not_like_debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدين الاجنبي لا يساوي مع المدين المحلي عند الصرف"])},
    "foreign_credit_local_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن استخدام دائن اجنبي بعملة محلية"])},
    "foreign_credit_not_like_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدائن الاجنبي لا يساوي مع الدائن المحلي عند الصرف"])},
    "debit_and_credit_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن ترك المدين والدائن خاليين"])},
    "foreign_debit_and_credit_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن ترك المدين والدائن الاجنبي خاليين"])},
    "invalid_analyst_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب تحليلي غير صالح"])},
    "analyst_account_messing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب التحليلي غير موجود"])},
    "debit_not_like_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي قيمة المدين لا تساوي اجمالي قيمة الدائن"])}
  },
  "report": {
    "visits-carried-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزيارات المنفذة لكل مديرية"])},
    "user-input-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بمدخلات المدارس والموظفين على حسب المستخدم"])},
    "students-dropout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بالطلاب المتسربين على مستوى المديرية"])},
    "other-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير إحصائية اخرى"])},
    "employee_avaliable_by_responsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توافر العاملين حسب المسئولية"])},
    "study-clander": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   التقويم الدراسي   "])},
    "employee-by-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" توفر العاملين حسب الأدوار   "])},
    "material-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستلزمات   "])},
    "droupout_resons_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير اسباب التسرب "])},
    "students-statistic-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب بالمستوى"])},
    "students-statistic-all-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب لكل المستويات"])},
    "approch-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المناهج "])},
    "committee_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير اعضاء اللجان "])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
    "student-name-cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب"])},
    "student-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب:"])},
    "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
    "visit_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الزوار"])},
    "committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير لجان الامتحانات"])},
    "committee_with_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير لجان الامتحانات مع طلابها"])},
    "correct_by_secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف تصحيح بالرقم السري"])},
    "student_by_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير للطلاب حسب اسم الاب"])},
    "student_by_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بيانات الطلاب"])},
    "student_mark_by_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير درجات الطلاب"])},
    "activity_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير  الجدول الاسبوعي للأنشطة"])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير المواهب للمستوى"])},
    "initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير جميع المبادرات"])},
    "initiatives_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير مشتركين مبادرة"])},
    "all_activities_by_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الطلاب المشتركين بالنشاط"])},
    "and_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والنشاط"])},
    "all_activities_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الأنشطة ككل حسب النوع "])},
    "all_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات عدد تنفيذ النشاط"])},
    "staff-meeting-minutes-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محضر اجتماع العاملين النهائي"])},
    "staff-meeting-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محضر اجتماع العاملين"])},
    "count_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد التنفيذ"])},
    "statistic_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المجتمع والجهات المساندة"])},
    "positives_negatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات السلبيات والايجابيات"])},
    "all_activities_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات النشاط"])},
    "detailed_statement_of_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب تفصيلي للرسوم الدراسية"])},
    "grade-semester-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الطلاب في "])},
    "students": {
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلاب"])},
      "by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب السنة-الفصل"])},
      "by-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب المحافظة"])},
      "by-nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب الجنسية"])},
      "by-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب ولي الأمر"])},
      "by-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب العمر"])},
      "by-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب المستخدمين"])},
      "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحريري"])},
      "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شفهي"])},
      "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات"])},
      "attendance_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواظبة"])}
    },
    "month_deficiencies_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نواقص الدرجات الشهرية لشهر"])},
    "month_grade_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجة امتحانات شهر"])},
    "student_has_spicific_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف بالطلاب الحاصلين على تقدير"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع مستخمين النظام"])},
    "commission-distr-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة توزيع اللجان"])},
    "optional-subject-student-degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير درجات المواد الاختياريه لطلاب"])},
    "count-of-actual-lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص"])},
    "count-of-attendence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور"])},
    "count-of-absentce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغياب"])},
    "count-of-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايام بعذر"])},
    "count-of-not-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايام بغير عذر"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
    "total-degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الدرجات النهائي"])},
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير العام"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعدل"])},
    "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتابة"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة"])},
    "collector-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحصله الاولى"])},
    "collector-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحصله الثانيه"])},
    "final-exam-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص العام"])},
    "final-exam-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية العام"])},
    "final-collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "final-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع النهائي"])},
    "subject-estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير"])},
    "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحريري"])},
    "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شفهي"])},
    "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات"])},
    "attendance_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواظبة"])},
    "parent_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولي الامر "])},
    "statement_grades_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات شهر "])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير ولي الامر"])},
    "student-governorates-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الطلاب  بحسب المحافظة "])},
    "highest_mark_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بأسماء الطلاب الأوائل ل عام"])},
    "optional-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الاختيارية لفصل"])},
    "teacher_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" جدول المدرس الاسبوعي"])},
    "attendance_day_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور والغياب اليومي"])},
    "attendance_month_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور والغياب لشهر"])},
    "student_with_seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير كشف ارقام الجلوس"])},
    "student_with_secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير طلاب مع ارقام السرية"])},
    "student_with_secretnumber_and_seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير طلاب مع ارقام الجلوس والارقام السرية"])},
    "student_without_secretnumber_and_seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير طلاب بدون ارقام الجلوس اوالارقام السرية"])},
    "schedule_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير جدول الحصص الاسبوعي"])},
    "certeficate-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادة السنوية"])},
    "studentWithoutSecretOrSeatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون رقم سري - جلوس   "])},
    "subjectNotAssignd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب لم تسند لهم مواد"])},
    "MarkNotAssignd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب لم تسجل لهم درجات المواد"])},
    "StudentWalkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المنسحبين"])},
    "deatils_fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير تفاصيل الرسوم"])},
    "fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير الرسوم حسب البند"])},
    "student_fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير حساب الطلاب حسب الرسوم  "])},
    "bus_info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بيانات الباصات"])},
    "mark_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات حسب السنة"])},
    "teacherreportbyteacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   تقرير حصص الاستاذ حسب الاستاذ"])},
    "collectormarkreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات المحصلة"])},
    "finalmarkreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الاختبارات النهائية"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير المتبقي حسب ولي الامر "])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير اولياء الامور"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة مادة"])},
    "parent_by_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير ولي الامر حسب الطالب"])},
    "student-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بالطلاب حسب السنة والصف"])},
    "student-ages-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بأعمار الطلاب حسب السنة والصف"])},
    "student-nationality-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الطلاب بالجنسية حسب السنة والصف"])},
    "student-images-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بصور الطلاب حسب الصف"])},
    "student-accounts-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بأسماء المستخدمين للطلاب حسب الصف"])},
    "student-all-data-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع بيانات الطلاب حسب السنة والصف"])},
    "attendens_by_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الحظور حسب الشهر"])},
    "teacher_info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بيانات الاساتذة"])},
    "first_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الأوائل لشهر "])},
    "first_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الأوائل لمادة "])},
    "student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف رسوم الطالب الدراسية"])},
    "students_in_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشاركين بالباص"])},
    "student_fees_Installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب الاقساط "])},
    "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج كشف الدرجات "])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير الطلاب المسجلين للعام"])},
    "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون بنود الرسوم دراسية "])},
    "without_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب مشتركين في الباصات بدون رسوم "])},
    "group_member_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع المستخدمين في المجموعة"])},
    "group_permissions_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع الصلاحيات للمجموعة"])},
    "subject_not_assignd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بمواد لم ترصد"])},
    "usernames_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تقرير اسماء المستخدمين لأولياء الامور"])},
    "report_header": {
      "Show_Halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض القاعات"])},
      "Students_by_Year_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب السنة والصف"])},
      "Students_by_Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب المحافظة"])},
      "Students_by_Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب الجنسية"])},
      "Students_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب ولي الامر"])},
      "Students_Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمار الطلاب"])},
      "Students_Users_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسماء المستخدمين (الطلاب)"])},
      "Students_Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور الطلاب"])},
      "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المستجدين"])},
      "Walk_out_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المنسحبين"])},
      "Students_Parents_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات اولياء امور الطلاب"])},
      "Personal_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية"])},
      "Usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسماء المستخدمين"])},
      "Parents_Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات تواصل اولياء الامور"])},
      "Students_without_Monthly_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون درجات شهرية"])},
      "Monthly_Deficiencies_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نواقص الدرجات الشهرية"])},
      "Monthly_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الدرجات الشهرية"])},
      "Monthly_Marks_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الدرجات للشهر الحالي"])},
      "Specific_Month_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات شهر معين"])},
      "Month_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة شهرية"])},
      "Month_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة شهرية انجليزية"])},
      "First_Class_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوائل الشهر"])},
      "Students_Estimation_by_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بتقدير شهري معين"])},
      "Subjects_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد لم ترصد"])},
      "Marks_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات لم ترصد"])},
      "Specific_Years_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة سنة معينة"])},
      "Specific_Years_English_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة سنة معينة انجليزي"])},
      "Years_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف اوائل السنة"])},
      "Year_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الدرجات السنوية"])},
      "Subjects_Review_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة مادة"])},
      "Semester_Optional_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد الفصل الاختيارية"])},
      "Specific_Semester_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة ترم معين انجليزي"])},
      "Semester_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الترم"])},
      "Specific_Semester_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة ترم معين"])},
      "Final_Exams_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الامتحانات النهائية"])},
      "Total_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات المحصلات"])},
      "Subjects_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف اوائل المادة"])},
      "Students_Subscribed_Buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشتركين في الباصات"])},
      "Buses_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف بيانات الباصات"])},
      "Rest_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي من الرسوم"])},
      "Student_Account_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب"])},
      "Student_Installments_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب اقساط"])},
      "Students_without_Study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون رسوم دراسية"])},
      "Students_without_Buses_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون رسوم باص"])},
      "Students_Subscribed_Buses_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف طلاب مشتركين بالباصات"])},
      "Student_Statement_Fee_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف طلاب حسب بند الرسوم"])},
      "Student_Statement_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب حسب بند"])},
      "Detailed_Statement_study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف تفصيلي للرسوم الدراسية"])},
      "Statement_Paid_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حسب المدفوع"])},
      "Teachers_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاساتذة"])},
      "Subscribers_Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاركين بالانشطة"])},
      "Student_Statement_Seat_Secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري و رقم الجلوس"])},
      "Student_Without_Seat_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون رقم سري او جلوس"])},
      "Committees_Distribution_Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير خطة توزيع اللجان"])},
      "Specific_Group_Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعضاء مجموعة معينة"])},
      "System_Users_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدمين النظام"])},
      "Specific_Group_Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات المجموعة"])},
      "Rest_Fees_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي من الرسوم"])},
      "Parent_by_Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أولياء الأمور حسب الطالب"])},
      "Exams_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الاختبارات"])},
      "Study_Schedule_by_Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الحصص حسب الأستاذ"])},
      "Student_Committees_by_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجان الطلاب حسب الصف"])},
      "Secret_No_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الرقم السري"])},
      "Study_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الدراسي"])},
      "Student_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير غياب الطالب"])},
      "Month_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف بالغياب شهري"])},
      "Daily_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور والغياب"])},
      "Weekly_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور لفترة"])},
      "Student_Seat_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف ارقام الجلوس"])},
      "Correction_by_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف التصحيح بالرقم السري"])},
      "Committees_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة كشوفات اللجان"])},
      "Exams_Review_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة الاختبارات"])},
      "Monthly_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور الشهري"])},
      "Monthly_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الشهرية"])},
      "Final_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير النهائية"])},
      "Semester_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الفصلية"])},
      "Exams_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الامتحانات"])},
      "Schedule_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول الحصص"])},
      "attendance_day_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور والغياب اليومي"])},
      "student_with_seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير كشف ارقام الجلوس"])},
      "student_with_secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير طلاب مع ارقام السرية"])},
      "student_with_secretnumber_and_seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير طلاب مع ارقام الجلوس والارقام السرية"])},
      "student_without_secretnumber_and_seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير طلاب بدون ارقام الجلوس اوالارقام السرية"])},
      "schedule_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير جدول الحصص الاسبوعي"])},
      "certeficate-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادة السنوية"])},
      "studentWithoutSecretOrSeatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون رقم سري - جلوس   "])},
      "subjectNotAssignd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب لم تسند لهم مواد"])},
      "MarkNotAssignd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب لم تسجل لهم درجات المواد"])},
      "StudentWalkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المنسحبين"])},
      "deatils_fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير تفاصيل الرسوم"])},
      "fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير حسب البند"])},
      "student_fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير حساب الطلاب حسب الرسوم  "])},
      "bus_info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بيانات الباصات"])},
      "mark_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات حسب السنة"])},
      "teacherreportbyteacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   تقرير حصص الاستاذ حسب الاستاذ"])},
      "collectormarkreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات المحصلة"])},
      "finalmarkreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الاختبارات النهائية"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير المتبقي حسب ولي الامر "])},
      "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير اولياء الامور"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة مادة"])},
      "parent_by_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير ولي الامر حسب الطالب"])},
      "student-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بالطلاب حسب الصف"])},
      "student-ages-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بأعمار الطلاب حسب السنة والصف"])},
      "student-nationality-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الطلاب حسب الجنسية "])},
      "student-images-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بصور الطلاب حسب الصف"])},
      "student-accounts-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بأسماء المستخدمين للطلاب حسب الصف"])},
      "student-all-data-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع بيانات الطلاب حسب السنة والصف"])},
      "attendens_by_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الحظور حسب شهر"])},
      "teacher_info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بيانات الاساتذة"])},
      "first_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الأوائل لشهر "])},
      "first_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الأوائل لمادة "])},
      "student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب"])},
      "student_fees_Installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب الاقساط "])},
      "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير الطلاب المسجلين للعام"])},
      "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون بنود الرسوم دراسية "])},
      "without_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب مشتركين في الباصات بدون رسوم "])},
      "group_member_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع المستخدمين في المجموعة"])},
      "group_permissions_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بجميع الصلاحيات للمجموعة"])},
      "subject_not_assignd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بمواد لم ترصد"])},
      "usernames_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تقرير اسماء المستخدمين لأولياء الامور"])},
      "report_header": {
        "Show_Halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض القاعات"])},
        "Students_by_Year_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب الصف"])},
        "Students_by_Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب المحافظة"])},
        "Students_by_Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب الجنسية"])},
        "Students_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب ولي الامر"])},
        "Students_Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمار الطلاب"])},
        "Students_Users_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسماء المستخدمين (الطلاب)"])},
        "Students_Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور الطلاب"])},
        "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المستجدين"])},
        "Walk_out_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المنسحبين"])},
        "Students_Parents_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات اولياء امور الطلاب"])},
        "Personal_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية"])},
        "Usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسماء المستخدمين"])},
        "Parents_Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات تواصل اولياء الامور"])},
        "Students_without_Monthly_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون درجات شهرية"])},
        "Monthly_Deficiencies_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نواقص الدرجات الشهرية"])},
        "Monthly_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الدرجات الشهرية"])},
        "Monthly_Marks_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" درجات مادة لشهر معين"])},
        "Specific_Month_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات شهر معين"])},
        "Month_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة شهرية"])},
        "Month_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة شهرية انجليزية"])},
        "First_Class_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوائل الشهر"])},
        "Students_Estimation_by_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بتقدير شهري معين"])},
        "Subjects_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد لم ترصد"])},
        "Marks_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات لم ترصد"])},
        "Specific_Years_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة سنة معينة"])},
        "Specific_Years_English_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة سنة معينة انجليزي"])},
        "Years_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف اوائل السنة"])},
        "Year_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الدرجات السنوية"])},
        "Subjects_Review_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة مادة"])},
        "Semester_Optional_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد الفصل الاختيارية"])},
        "Specific_Semester_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة ترم معين انجليزي"])},
        "Semester_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الترم"])},
        "Specific_Semester_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة ترم معين"])},
        "Final_Exams_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الامتحانات النهائية"])},
        "Total_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات المحصلات"])},
        "Subjects_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف اوائل المادة"])},
        "Students_Subscribed_Buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشتركين في الباصات"])},
        "Buses_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف بيانات الباصات"])},
        "Rest_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي من الرسوم"])},
        "Student_Account_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب"])},
        "Student_Installments_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب اقساط"])},
        "Students_without_Study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون رسوم دراسية"])},
        "Students_without_Buses_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون رسوم باص"])},
        "Students_Subscribed_Buses_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف طلاب مشتركين بالباصات"])},
        "Student_Statement_Fee_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف طلاب حسب بند الرسوم"])},
        "Student_Statement_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب حسب بند"])},
        "Detailed_Statement_study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف تفصيلي للرسوم الدراسية"])},
        "Statement_Paid_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حسب المدفوع"])},
        "Teachers_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاساتذة"])},
        "Subscribers_Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاركين بالانشطة"])},
        "Student_Statement_Seat_Secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري و رقم الجلوس"])},
        "Student_Without_Seat_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون رقم سري او جلوس"])},
        "Committees_Distribution_Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير خطة توزيع اللجان"])},
        "Specific_Group_Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعضاء مجموعة معينة"])},
        "System_Users_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدمين النظام"])},
        "Specific_Group_Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات المجموعة"])},
        "Rest_Fees_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي من الرسوم"])},
        "Parent_by_Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أولياء الأمور حسب الطالب"])},
        "Exams_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الاختبارات"])},
        "Study_Schedule_by_Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الحصص حسب الأستاذ"])},
        "Student_Committees_by_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجان الطلاب حسب الصف"])},
        "Secret_No_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الرقم السري"])},
        "Study_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الدراسي"])},
        "Student_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير غياب الطالب"])},
        "Month_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف بالغياب شهري"])},
        "Daily_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور والغياب"])},
        "Weekly_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور الأسبوعي"])},
        "Student_Seat_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف ارقام الجلوس"])},
        "Correction_by_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف التصحيح بالرقم السري"])},
        "Committees_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة كشوفات اللجان"])},
        "Exams_Review_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة الاختبارات"])},
        "Monthly_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور الشهري"])},
        "Monthly_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الشهرية"])},
        "Final_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير النهائية"])},
        "Semester_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الفصلية"])},
        "Exams_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الامتحانات"])},
        "Schedule_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول الحصص"])}
      },
      "new_enrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الطلاب المسجلين"])},
      "student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب المشتركين في الباصات "])},
      "student_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشاركين في النشاط"])}
    },
    "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البند"])},
    "new_enrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الطلاب المسجلين"])},
    "student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب المشتركين في الباصات "])},
    "student_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشاركين في النشاط"])},
    "study-start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البدء في الدراسة"])},
    "study-end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتهاء في الدراسة"])},
    "summer-center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الدورة الصيفية "])},
    "general-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الادارة العامة"])},
    "financial-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الادارة المالية"])},
    "governorate-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير المحافظة"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير المديرية"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير يومية"])}
  },
  "nationality": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر الجنسية"])}
  },
  "exam": {
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجة امتحان "])},
    "seat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجلوس "])},
    "secret-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري "])},
    "seat-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ارقام الجلوس"])},
    "secret-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  الارقام السرية"])},
    "dist-seat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع ارقام الجلوس"])},
    "dist-secret-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الارقام السرية"])}
  },
  "branch": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفرع"])}
  },
  "directorate": {
    "code-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المديرية"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مديرية"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مديرية"])}
  },
  "governorate": {
    "code-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المحافظة"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المحافظة"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد محافظة"])}
  },
  "backup": {
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسخ الاحتياطي"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء نسخة احتياطية"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة نسخة احتياطية"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات النسخ الاحتياطي"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الصيغة"])},
    "file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الملف"])},
    "ask-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل تريد انشاء نسخة احتياطية ؟ "])}
  },
  "activity": {
    "activity_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات النشاط"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع النشاط"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
    "gendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة المشاركة"])},
    "is_run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنفيذ"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البدأ"])},
    "statrt_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وقت بداية النشاط"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتهاء"])},
    "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وقت نهاية النشاط"])},
    "employee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العامل المسؤول"])},
    "employee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل اسم العامل المسؤول"])},
    "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل موقع النشاط"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع النشاط"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم للمستلزمات"])},
    "evaluation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل تقييم النشاط "])},
    "evaluation_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم النشاط "])},
    "dificult_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصعوبات التي واجهت تنفيذ النشاط"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المشاركين"])},
    "activtity_assigin_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التعيين"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة"])},
    "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط جديد"])},
    "add_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحل"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر النشاط"])},
    "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤول النشاط"])},
    "desc-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف نوع النشاط"])},
    "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط رياضي"])},
    "cultural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط ثقافي"])},
    "entertaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط ترفيهي"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بداية النشاط"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم النشاط"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكان"])},
    "placeholder": {
      "student-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المشاركين بالنشاط"])}
    },
    "difficultie_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل صعوبة "])},
    "solutions_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل حل"])},
    "difficultie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصعوبة"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحل"])},
    "difficulties_and_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصعوبات والحلول"])},
    "difficulties_and_solutions_assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعيين الصعوبات والحلول"])},
    "difficulties_and_solutions_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض الصعوبات والحلول "])},
    "show_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الانشطة"])},
    "add-dif-sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة صعوبات وحلول"])},
    "del-dif-sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف صعوبات وحلول"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدف"])},
    "school-recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيات للمدرسة"])},
    "media-recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيات للاعلام"])},
    "participating-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات المشاركة"])},
    "entity-to-coordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهات المطلوب التنسيق معها"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد التنفيذ"])},
    "iteration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار النشاط"])}
  },
  "employee": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموظفين"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الموظفين"])},
    "add-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة موظف"])},
    "shared-in-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك بالدورات الصيفية سابقا ؟"])},
    "no-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تقم بإضافة موظفين ."])}
  },
  "circular": {
    "circular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعميم"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التعميم"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التعميم "])},
    "show-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمن يظهر التعميم"])},
    "circulars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعميمات"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تعميمات"])}
  },
  "globals": {
    "schools-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدارس"])},
    "employees-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الموظفين"])},
    "schools-statstics-per-gov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المدارس على مستوى المحافظات"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة"])},
    "select-excel-sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار ورقة العمل المناسبة"])},
    "there-are-many-sheets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم اكتشاف أكثر من ورقة عمل في هذا الملف الذي قمت بتحميله. لتتمكن من متابعة العملية, يرجى اختيار واحدة من الاوراق التالية:"])},
    "for-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمحافظة"])},
    "for-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمديرية"])},
    "for-summer-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للدورة الصيفية"])},
    "for-all-governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لكل المحافظات"])},
    "for-all-directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لكل المديريات"])},
    "for-all-summer-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لكل الدورات الصيفية"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معطل"])},
    "review-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة البيانات"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة"])},
    "is-reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت المراجعة"])},
    "errors-of-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاخطاء على هذا السجل"])},
    "certificate-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الشهادة"])},
    "gift-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الهدية"])},
    "license-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الترخيص"])},
    "sport-accessory-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المستلزمات الرياضية"])},
    "sport-accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلزمات الرياضية"])},
    "license-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترخيص - السجل"])},
    "gift-prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدية - الجائزة"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادة"])},
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطائق"])},
    "females": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أناث"])},
    "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
    "sub-district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العزلة"])},
    "village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القرية"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة"])},
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة"])},
    "grand-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي الكلي"])},
    "content-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المحتوى"])},
    "upload-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع البيانات"])},
    "only-uploaded-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فقط رفعت بيانات"])},
    "file-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الملف يجب ان يكون اقل من "])},
    "extension-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون امتداد الملف ضمن "])},
    "app-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مميزات التطبيق"])},
    "upload-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الرفع"])},
    "app-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط التحميل"])},
    "application-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف التطبيق"])},
    "downloadable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابل للتحميل"])},
    "data-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدخل البيانات"])},
    "updated-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل البيانات"])},
    "updated-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تاريخ التعديل"])},
    "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإضافة"])},
    "delete-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد عملية الحذف"])},
    "school-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستويات الدراسية"])},
    "page-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة غير موجودة"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
    "page-not-found-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعتذر!, ولكن الصفحة التي كنت تبحث عنها غير موجودة."])},
    "new-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب جدد"])},
    "studied-last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درسوا العام الماضي"])},
    "talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواهب"])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموهبة"])},
    "visiting-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة الزائرة"])},
    "visiting-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد مرات الزيارة"])},
    "executed-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مرات التنفيذ"])},
    "executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنفيذ"])},
    "task-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المهمه"])},
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسجلين"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتظمين"])},
    "identity-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة الشخصية"])},
    "specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
    "qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل"])},
    "employee-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الموظف"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموظفين"])},
    "login-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدخول الى حسابك "])},
    "login-account-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدخول الى حسابك في نظام الدورات الصيفية"])},
    "remeber-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذكرني ؟"])},
    "num-of-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الزيارات"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحصائيات"])},
    "async-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزامنة الاحصائيات"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "indoor-accomodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سكن داخلي "])},
    "outdoor-accomodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سكن خارجي "])},
    "days-absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايام غياب"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة المحاولة"])},
    "year-month-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة-شهر-يوم"])},
    "present-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب الحاضرين"])},
    "present-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموظفين الحاضرين"])},
    "male_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدارس الذكور"])},
    "fmale_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدارس الاناث"])},
    "school-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الدراسي"])},
    "school-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسة/الجامعة التي يدرس بها الطالب "])},
    "total_schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المدارس"])},
    "total_workers_male_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع عاملين / عاملات"])},
    "totla_male_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الطلاب"])},
    "total_female_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الطالبات"])},
    "total_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الطلاب"])},
    "activaty_group_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جماعات الأنشطة"])},
    "group_activaty_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنشطة الجماعات"])},
    "committee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعضاء اللجان"])},
    "recive_approch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنصرف من المناهج"])},
    "choices-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التقرير بتاريخ اليوم"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات"])},
    "LectureLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل المحاضرات"])},
    "not_all_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم حفظ جميع السجلات"])},
    "#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["م"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
    "system-summer-center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام  الدورات الصيفية"])},
    "system-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام المدرسة "])},
    "males": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكور"])},
    "fmales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اناث"])},
    "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباحي"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسائي"])},
    "morning$afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباحي و مسائي"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم"])},
    "adjective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفه"])},
    "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايجابيات"])},
    "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلبيات"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايوجد بيانات"])},
    "assigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
    "in_the_name_of_allah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بسم الله الرحمن الرحيم"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 00967777777777"])},
    "rep-of-yemen-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمهورية اليمنية"])},
    "rep-of-yemen-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Yemen"])},
    "ministry-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزارة التربية والتعليم"])},
    "ministry-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Education"])},
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيقات"])},
    "summer-centre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدورة الصيفية"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسة"])},
    "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
    "default-choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيارات تلفائية"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة"])},
    "select-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد مسار الملف"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "home_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الثابت"])},
    "messing_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال الحقول الفارغة"])},
    "student_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطالب"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد"])},
    "will-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سيتم حذف البيانات التالية :"])},
    "dist-dynamically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع تلقائي"])},
    "public-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم عام"])},
    "am-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباحاَ"])},
    "pm-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساءَ"])},
    "no-periods-pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فترات مسائية"])},
    "no-periods-am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فترات صباحية"])},
    "select-criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد معايير الفلترة"])},
    "filter-students-by-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلترة الطلاب حسب العنوان"])},
    "select-others-criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد معايير الفلترة"])},
    "all-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل السجلات"])},
    "shown-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السجلات المعروضة"])},
    "not-found-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد سجلات "])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنصر"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ... تحميل"])},
    "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الايميل موجود من قبل"])},
    "student-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طالب بهذا الاسم "])},
    "student-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث باسم الطالب"])},
    "employee-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث باسم العامل"])},
    "daily-follow-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة يومية"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم"])},
    "correction-standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction Standard"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
    "current_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الحالي"])},
    "hone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الثابت"])},
    "print-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة ملف"])},
    "installment_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابل للتقسيط"])},
    "subscribe_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاشتراك"])},
    "reducible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابل للتخفيض"])},
    "portability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابلية النقل"])},
    "percentage_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يجب ان تتعدى النسبة عن 100"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة مئوية"])},
    "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البداية"])},
    "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النهاية"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداية"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريح النهاية"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
    "fee_class_exceist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الفصل ونوع الرسوم مختار من قبل"])},
    "student-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
    "num-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد الأشهر "])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختياري"])},
    "obligatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجباري"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح  ^_^"])},
    "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل"])},
    "committee_excest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه اللجنة والصف قد تم اختيارهم من قبل"])},
    "errpr_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك حذف الشهر الحالي"])},
    "error_in_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرحى مراجعة البيانات المدخلة"])},
    "group_for_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن الغاء ظهور مجموعة تم اسنادها لحساب مستخدم"])},
    "e_in_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرحى تحديد محافظة"])},
    "future-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن التحضير في المستقبل"])},
    "date_out_of_ring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ خارج حدود التقويم"])},
    "error_in_repeted_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  البيانات فريدة ولايمكن تكرارها "])},
    "error_student_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لايمكن حذف الطالب لارتباطه بسحلات اخرى "])},
    "parent-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولى الامر هذا لديه طلاب"])},
    "data_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت اضافة البيانات بنجاح"])},
    "data_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت حفظ البيانات بنجاح"])},
    "data_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف البيانات بنجاح"])},
    "deleted_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الحذف"])},
    "data_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل البيانات بنجاح"])},
    "updated_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل التعديل"])},
    "view_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلترات العرض"])},
    "max_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكبر تاريخ مسموح به هو "])},
    "max_date_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكبر تاريخ مسموح به اقل من "])},
    "min_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصغر تاريخ مسموح به هو "])},
    "min_date_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصغر تاريخ مسموح به اكبر من "])},
    "wrong_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات المدخلة لا تناسب النوع المختار"])},
    "max_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت الحد الاعلى لعدد الارقام"])},
    "max_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت اكبر رقم مسموح به"])},
    "min_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصغر رقم مسموح به هو"])},
    "min_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الادنى لعدد الارقام هو"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تعديت الحد الاعلى لعدد الحروف"])},
    "min_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تعديت الحد الادنى لعدد الحروف"])},
    "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقل إلزامي"])},
    "hall_excist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا القاعة مستخدمة من قبل"])},
    "division_excist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الشعبة قد تم تحديدها من قبل"])},
    "globals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك في نظام عام"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقل"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلترة"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن التراجع عن هذا الإجراء. بمجرد الحذف, ستتم إزالة السجل بشكل دائم ولا يمكن استعادته. يتضمن ذلك جميع البيانات المرتبطة."])},
    "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد من تعديل هذا السجل ؟"])},
    "complete_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم الحذف بنجاح ! "])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "sequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسلسل"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخصص"])},
    "email_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايميل غير صالح"])},
    "lowest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقل عدد ممكن هو "])},
    "biggest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكبر عدد ممكن هو "])},
    "must_be_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أرقام فقط !"])},
    "must_be_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أحروف عربية فقط !"])},
    "must_be_telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون حساب تليجرام !"])},
    "must_be_full_letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من حروف  فقط !"])},
    "must_be_letters_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أحروف إنجليزية فقط !"])},
    "must_be_letters_and_numbers_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أحروف إنجليزية وارقام فقط !"])},
    "must_be_between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون عدد الأحرف ما بين "])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" .ملاحظات"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الايميل"])},
    "placeholder_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كلمة المرور "])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخول"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروج"])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرية"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم عربي"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم انجليزي"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  نشط"])},
    "unactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الجنسية"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الابناء"])},
    "basic-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استاذ أساسي"])},
    "service-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حالة الخدمة"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مكان الميلاد"])},
    "num-of-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد الوظائف"])},
    "date-of-employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تاريخ التوظيف"])},
    "job-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الوظيفة"])},
    "work-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فترة العمل"])},
    "marital-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحالة الاجتماعية"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملاحظة"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عازب"])},
    "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" متزوج "])},
    "employment-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات التوظيف "])},
    "personal-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البيانات الشخصية "])},
    "contact-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات التواصل "])},
    "user-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المستخدم"])},
    "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساءً"])},
    "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباحاً"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكسل"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
    "confirm-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد أنك تريد حذف هذا السجل ؟"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسناَ"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظة"])},
    "student_count_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تخطيت عدد الطلاب المسموح بهم بهذه الشعبة"])},
    "documents_per_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الوثائق مطلوبة لهذا الفصل"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ !"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "complete-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم الحذف بنجاح ! "])},
    "complete-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم التعديل بنجاح ! "])},
    "error-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خطأ في عملية التعديل !  "])},
    "error-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خطأ في عملية الادخال !  "])},
    "error-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خطأ في عملية الحذف !  "])},
    "complete-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تمت الاضافة بنجاح ! "])},
    "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
    "addmember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عضو"])},
    "addauthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مؤلف"])},
    "per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناصر في كل صفحة"])},
    "box-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صندوق البريد"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع الالكنروني"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موبايل"])},
    "addpublisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة ناشر"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاكس"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
    "search-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث هنا "])},
    "system-adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة النظام "])},
    "users-adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المستخدمين"])},
    "system-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة النظام"])},
    "general-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التهيئة العامة"])},
    "school-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقويم الدراسي"])},
    "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفوف"])},
    "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعات"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
    "classes-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الصف"])},
    "subjects-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المواد الدراسية"])},
    "distribute-subjects-classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع المواد على الصفوف"])},
    "grades-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الدرجات"])},
    "estimates-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة التقديرات"])},
    "student-fee-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة رسوم الطلاب"])},
    "fee-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات الرسوم "])},
    "discount-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات التخفيضات"])},
    "student-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة شئون الطلاب"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
    "addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
    "registration-addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل والقبول"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
    "follow-up-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الطلاب"])},
    "fee-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسديد الرسوم"])},
    "new-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
    "teacher-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الاستاذ مستخدم"])},
    "driver-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا السائق مستخدم"])},
    "seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجلوس"])},
    "secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري"])},
    "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة"])},
    "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطالب"])},
    "teacher-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البيانات الشخصية للعاملين"])},
    "teacher-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاسم مع اللقب"])},
    "teacher-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  رقم الهاتف "])},
    "teacher-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاسم مع اللقب"])},
    "teacher-specail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخصص المدرس"])},
    "teacher-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   السكن الحالي"])},
    "installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
    "students-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب"])},
    "homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
    "student-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الطالب"])},
    "distribute-student-on-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الطلاب على الشعب"])},
    "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكنترول"])},
    "bus-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الباصات"])},
    "score-recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدرجات"])},
    "monthly-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الشهرية"])},
    "enter-outcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخال المحصلة"])},
    "semester-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الترم"])},
    "homework-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الواجبات"])},
    "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامتحانات"])},
    "seats-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارقام الجلوس"])},
    "examination-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الامتحانات  "])},
    "examination-table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الامتحانات  "])},
    "distribute-students-on-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الطلاب    "])},
    "sections-exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع اللجان"])},
    "class-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الدراسي"])},
    "elevate-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
    "substitute-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرس البديل "])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص"])},
    "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصة"])},
    "lecture-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان المحاضرة"])},
    "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاضرات"])},
    "financial-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الشئوون المالية"])},
    "journal-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد يومي"])},
    "catch-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سند قبض "])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سند صرف"])},
    "accounts-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الحسابات"])},
    "funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصناديق"])},
    "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملات"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة"])},
    "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنوك"])},
    "students-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الطالب"])},
    "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هيئة التدريس"])},
    "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المدرسين"])},
    "teacher-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مدرس"])},
    "student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة الطلابية "])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدمين"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعات"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات"])},
    "school-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة "])},
    "study-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" السنوات الدراسية "])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع"])},
    "study-lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص الدراسية  "])},
    "documents-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الوثائق  "])},
    "nationalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسيات"])},
    "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات"])},
    "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات"])},
    "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المباني"])},
    "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوقف"])},
    "continus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستمر"])},
    "drop-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منقطع"])},
    "job-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوظيفي"])},
    "view_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض القاعات"])},
    "add_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قاعة"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوقات الحصص"])},
    "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابتدائي"])},
    "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانوي"])},
    "preparatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادي"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء الامور"])},
    "parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم ولي الامر"])},
    "parent-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة ولي امر"])},
    "parent-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اولياء الامور"])},
    "committeeplan-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطة اللجان"])},
    "committeeplan-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطة اللجان"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "republic_of_yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمهورية اليمنية"])},
    "teacher-class-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم اسناد هذا المدرس لهذا الفصل بهذه المادة"])},
    "one-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة واحدة"])},
    "two-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترتين"])}
  },
  "year": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الدراسية"])},
    "name-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  السنة الهجرية"])},
    "name-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  السنة الميلادية"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر السنة"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة سنة دراسية جديدة"])},
    "select-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اختر السنة الهجرية"])},
    "select-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر السنة الميلادية "])},
    "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الحالية"])},
    "placeholder": {
      "year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 1444/1445 "])},
      "year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 2023/2024  "])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الايميل"])},
      "placeholder_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور "])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخول"])},
      "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروج"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرية"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
      "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم عربي"])},
      "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم انجليزي"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  نشط"])},
      "unactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الجنسية"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الابناء"])},
      "basic-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استاذ أساسي"])},
      "service-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حالة الخدمة"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مكان الميلاد"])},
      "num-of-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد الوظائف"])},
      "date-of-employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تاريخ التوظيف"])},
      "job-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الوظيفة"])},
      "work-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فترة العمل"])},
      "marital-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحالة الاجتماعية"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملاحظة"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عازب"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" متزوج "])},
      "employment-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات التوظيف "])},
      "personal-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات شخصية "])},
      "contact-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات التواصل "])},
      "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])},
      "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء"])},
      "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكسل"])},
      "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
      "confirm-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد أنك تريد حذف هذا السجل ؟"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسناَ"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير"])},
      "student_count_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تخطيت عدد الطلاب المسموح بهم بهذه الشعبة"])},
      "documents_per_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الوثائق مطلوبة لهذا الفصل"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ !"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "complete-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم الحذف بنجاح ! "])},
      "complete-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم التعديل بنجاح ! "])},
      "error-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خطأ في عملية التعديل !  "])},
      "error-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خطأ في عملية الادخال !  "])},
      "error-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خطأ في عملية الحذف !  "])},
      "complete-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تمت الاضافة بنجاح ! "])},
      "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
      "addmember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عضو"])},
      "addauthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مؤلف"])},
      "per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناصر في كل صفحة"])},
      "box-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صندوق البريد"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع الالكنروني"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موبايل"])},
      "addpublisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة ناشر"])},
      "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاكس"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
      "search-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث هنا "])},
      "system-adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة النظام "])},
      "users-adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المستخدمين"])},
      "system-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة النظام"])},
      "general-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التهيئة العامة"])},
      "school-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقويم الدراسي"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفوف"])},
      "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعات"])},
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
      "classes-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الصف"])},
      "subjects-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المواد"])},
      "distribute-subjects-classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع المواد على الصفوف"])},
      "grades-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الدرجات"])},
      "estimates-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة التقديرات"])},
      "student-fee-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة رسوم الطلاب"])},
      "fee-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات الرسوم "])},
      "discount-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات التخفيضات"])},
      "student-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة شئون الطلاب"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
      "addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
      "registration-addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل والقبول"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
      "follow-up-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الطلاب"])},
      "fee-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسديد الرسوم"])},
      "new-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
      "installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
      "students-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب"])},
      "homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
      "student-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الطالب"])},
      "distribute-student-on-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الطلاب على الشعب"])},
      "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكنترول"])},
      "bus-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الباصات"])},
      "score-recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدرجات"])},
      "monthly-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الشهرية"])},
      "enter-outcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخال المحصلة"])},
      "semester-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الترم"])},
      "homework-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الواجبات"])},
      "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامتحانات"])},
      "seats-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارقام الجلوس"])},
      "examination-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الامتحانات  "])},
      "examination-table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الامتحانات  "])},
      "distribute-students-on-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الطلاب على اللجان  "])},
      "sections-exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع اللجان"])},
      "class-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الدراسي"])},
      "elevate-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
      "substitute-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرس البديل "])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص"])},
      "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة"])},
      "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرات"])},
      "financial-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الشئوون المالية"])},
      "journal-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد يومي"])},
      "catch-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سند قبض "])},
      "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سند صرف"])},
      "accounts-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الحسابات"])},
      "funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصناديق"])},
      "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملات"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة"])},
      "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنوك"])},
      "students-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابات الطلاب"])},
      "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هيئة التدريس"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسين"])},
      "teacher-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مدرس"])},
      "student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة الطلابية "])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
      "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعات"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات"])},
      "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات"])},
      "school-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المدرسة "])},
      "study-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" السنوات الدراسية "])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع"])},
      "study-lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص الدراسية  "])},
      "documents-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الوثائق  "])},
      "nationalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسيات"])},
      "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات"])},
      "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات"])},
      "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المباني"])},
      "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوقف"])},
      "continus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستمر"])},
      "job-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوظيفي"])},
      "view_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض القاعات"])},
      "add_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قاعة"])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوقات الحصص"])},
      "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابتدائي"])},
      "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانوي"])},
      "preparatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادي"])},
      "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء الامور"])},
      "parent-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظافة ولي امر"])},
      "parent-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء الامور"])},
      "committeeplan-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطة اللجان"])},
      "committeeplan-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطة اللجان"])},
      "teacher-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الاستاذ مستخدم"])},
      "driver-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا السائق مستخدم"])},
      "for-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعام الدراسي"])},
      "name-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  السنة الهجرية"])},
      "name-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  السنة الميلادية"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر السنة"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة سنة دراسية جديدة"])},
      "select-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اختر السنة الهجرية"])},
      "select-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر السنة الميلادية "])},
      "placeholder": {
        "year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 1444/1445 "])},
        "year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 2023/2024  "])}
      },
      "teacher-subject-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة مواد المدرسين لهذه السنة"])},
      "class-student-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجة الطلاب لهذه السنة  "])},
      "class-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة الطلابية لهذه السنة"])},
      "homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات الطلاب لهذه السنة"])},
      "student-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم الطلاب لهذه السنة"])}
    }
  },
  "lecture": {
    "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدروس"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدرس"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدرس"])},
    "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد الدروس"])},
    "prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد المحاضرات"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة درس"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الدرس"])},
    "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الدروس"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الدروس "])},
    "adds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الدروس "])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد درس بهذه البيانات"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطة الدراسية"])},
    "studied-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص المدروسة"])}
  },
  "class": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصف"])},
    "for-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للصف"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة صف جديد"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الصف"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اسم الصف عربي"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصف انجليزي"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الصف"])},
    "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرحلة الدراسية"])},
    "ministerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هذا الفصل وزاري ؟"])},
    "dedicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخصص"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظامي"])}
  },
  "semester": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة ترم جديد"])},
    "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترم"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الترم عربي"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الترم انجليزي"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   اختر الترم"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشهر الترم"])}
  },
  "division": {
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعبة"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة شعبة جديدة"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الشعبة "])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  رمز الشعبة"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   اختر الشعبة"])},
    "select-multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الشعب "])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد شعب"])}
  },
  "document": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة وثيقة"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الوثائق"])},
    "add-to-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة وثائق الصف"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوثيقة"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الوثيقة"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ! لا توجد وثيقة بهذا الاسم "])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الوثيقة اختيارية للفصل ؟ "])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختيارية"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجبارية"])}
  },
  "skill": {
    "select-multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المهارات"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المهارة"])}
  },
  "school": {
    "is_continued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستمر ؟"])},
    "is_regestered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسجل ؟"])},
    "change_student_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير حالة الطالب"])},
    "edit_exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل جدول الاختبارات"])},
    "add_exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جدول "])},
    "exam_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم الاختبار"])},
    "final_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبار النهائي"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاختبار"])},
    "exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الجداول "])},
    "fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الرسوم"])},
    "fee_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الرسوم"])},
    "fee_types_bus_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن اختيار اكثر من نوع رسوم باص"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
    "fees_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنود الرسوم"])},
    "add_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة قاعة للخطة"])},
    "edit_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير خطة القاعات"])},
    "assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة القاعات"])},
    "add_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مدرس للخطة"])},
    "edit_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير خطة المدرسين"])},
    "assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة التدريس "])},
    "discount_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدار الخصم"])},
    "discount_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخصم"])},
    "placeholder_discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل فئة الخصم"])},
    "discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الخصم"])},
    "add_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فئات الخصم"])},
    "schedule_timing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البداية لا يمكن ان يتجاوز وقت النهاية"])},
    "schedule_timing_error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النهاية لايتجاوز الواحدة واالنصف بالفترة الصباحي"])},
    "schedule_timing_error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البداية لايقل عن الثامنة  بالفترة الصباحي"])},
    "schedule_timing_error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يجب ان تتداخل اوقات الفترات"])},
    "schedule_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت نهاية الحصة"])},
    "schedule_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت بداية الحصة"])},
    "evening_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة المسائية"])},
    "morning_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة الصباحية"])},
    "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
    "placeholder_schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الحصة"])},
    "schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصة"])},
    "add_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة حصة"])},
    "placeholder_hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الطاقة الاستيعابية للقاعة"])},
    "hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الاستيعابية"])},
    "placeholder_hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الطابق"])},
    "hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطابق"])},
    "placeholder_hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم القاعة"])},
    "hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القاعة"])},
    "edit_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قاعة"])},
    "add_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قاعة"])},
    "placeholder_building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الكافتريات"])},
    "building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الكافتريات"])},
    "placeholder_building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد دورات المياه"])},
    "building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد دورات المياه"])},
    "placeholder_building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الغرف"])},
    "building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الغرف"])},
    "placeholder_building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مكان المبنى"])},
    "building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان المبنى"])},
    "placeholder_building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الطوابق"])},
    "building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطوابق"])},
    "placeholder_building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المبنى"])},
    "building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المبنى"])},
    "add_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مبنى"])},
    "main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع الرئيسي"])},
    "main_branch_must_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك فرع رئيسي"])},
    "placeholder_branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل احداثيات الفرع"])},
    "branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احداثيات الفرع"])},
    "placeholder_branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل موقع الفرع"])},
    "branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الفرع"])},
    "is_main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع الرئيسي"])},
    "placeholder_branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الفرع بالانجليزي"])},
    "branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع بالانجليزي"])},
    "placeholder_branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الفرع بالعربي"])},
    "branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع بالعربي"])},
    "branch_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الفرع"])},
    "branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الفرع"])},
    "add_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فرع"])},
    "placeholder_branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسم مدير الفرع"])},
    "branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير الفرع"])},
    "main_branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الفرع الرئيسي"])},
    "ministry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الوزارة"])},
    "school_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المدرسة في الوزارة"])},
    "countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدول"])},
    "add_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة دولة"])},
    "country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة"])},
    "country_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة انجليزي"])},
    "nationality_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجنسية"])},
    "nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجنسية انجليزي"])},
    "placeholder_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الدولة"])},
    "placeholder_country_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الدولة بالانجليزي"])},
    "placeholder_nationality_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الجنسية"])},
    "placeholder_nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الجنسية بالانجليزي"])},
    "placeholder_country_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل وصف للدولة"])},
    "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المحافظات"])},
    "add_governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة محافظة"])},
    "governorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة"])},
    "governorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة انجليزي"])},
    "placeholder_governorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المحافظة بالعربي"])},
    "placeholder_governorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المحافظة بالانجليزي"])},
    "placeholder_governorate_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وصف للمحافظة"])},
    "add_directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مديرية"])},
    "directorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية"])},
    "directorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية انجليزي"])},
    "placeholder_directorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المديرية بالعربي"])},
    "placeholder_directorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المديرية بالانجليزي"])},
    "placeholder_directorate_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وصف للمديرية"])},
    "school_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدرسة عربي"])},
    "placeholder_school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المدرسة بالعربي"])},
    "school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدرسة انجليزي"])},
    "placeholder_school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المدرسة بالانجليزي"])},
    "establish_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التأسيس"])},
    "ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوزارة"])},
    "placeholder_ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الوزارة"])},
    "education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتب التربية"])},
    "placeholder_education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم مكتب التربية"])},
    "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص"])},
    "placeholder_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الترخيص"])},
    "license_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الترخيص"])},
    "school_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المدرسة"])},
    "typical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذجية"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة"])},
    "civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهلي"])},
    "governmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حكومي"])},
    "headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير المدرسة"])},
    "placeholder_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم مدير المدرسة"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع المدرسة"])},
    "placeholder_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مكان المدرسة"])},
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احداثيات المدرسة"])},
    "placeholder_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل احداثيات المدرسة"])},
    "coordinates_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الموقع"])},
    "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلاب"])},
    "school_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار المدرسة"])},
    "boys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنين"])},
    "girls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنات"])},
    "main_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الاساسية"])},
    "location_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموقع"])},
    "ministry_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الوزارة"])},
    "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات التواصل"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة التواصل"])},
    "contact_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع وسيلة التواصل"])},
    "parent_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم هاتف ولي الامر"])},
    "student_academic_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطالب الاكاديمية"])},
    "academic_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الاكاديمي"])},
    "card_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوطني"])},
    "add_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ولي أمر"])},
    "edit_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل ولي أمر"])},
    "show_registered_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المسجلين"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة طالب"])},
    "student_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب"])},
    "status_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة الدراسية"])},
    "student_ar_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب عربي"])},
    "student-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالبــ/ـة"])},
    "student_en_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب انجليزي"])},
    "student_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنسية الطالب"])},
    "parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم ولي الامر"])},
    "student_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
    "parent_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلة القرابة مع ولي الامر"])},
    "student_birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد "])},
    "student_birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الميلاد "])},
    "student_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "student_registration_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة استمارة التسجيل"])},
    "student_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الطالب"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
    "students_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلاب"])},
    "student_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطالب"])},
    "parent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات ولي امر الطالب"])},
    "student_docunent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الطالب"])},
    "student_financial_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطالب المالية"])},
    "student_ar_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الطالب بالعربي"])},
    "student_en_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الطالب بالانجليزي"])},
    "student_birth_place_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مكان الميلاد"])},
    "parent_relation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب صلة القرابة"])},
    "student_address_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عنوان الطالب"])},
    "student_registration_form_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل صورة استمارة تسجيل الطلب"])},
    "student_image_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل صورة الطلب"])},
    "notes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب هنا الملاحظات"])},
    "parentList_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اولياء الامور"])},
    "select_parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ولي امر  "])},
    "student_parent_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم ولي الامر"])},
    "student_parent_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم ولي الامر"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الهوية"])},
    "parent_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
    "parent_id_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رقم الهوية"])},
    "parent_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة"])},
    "parent_job_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب الوظيفة"])},
    "parent_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التلفون"])},
    "parent_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رقم التلفون"])},
    "parent_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "parent_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب العنوان"])},
    "parent_landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الثابت"])},
    "parent_landline_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رقم الثابت"])},
    "parent_id_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الهوية"])},
    "add_examPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فترات امتحانية"])},
    "examPeriod_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة الامتحان"])},
    "examPeriod_start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الامتحان"])},
    "examPeriod_end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الامتحان"])},
    "examPeriod_period_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل فترة الامتحان"])},
    "examPeriod_start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل بداية الامتحان"])},
    "examPeriod_end_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نهاية الامتحان"])},
    "title_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللجان الامتحانية"])},
    "add_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة لجنة "])},
    "committee_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعة"])},
    "committee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اللجنة"])},
    "edit_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل بيانات اللجنة"])},
    "committee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم اللجنة"])},
    "committeesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اللجان"])},
    "title_committeePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط اللجنان الامتحانية"])},
    "add_committeePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطة لجنة امتحانية"])},
    "edit_committeePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل بيانات خطةاللجنة"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف"])},
    "committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللجنة"])},
    "student_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد الطلاب"])},
    "student_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الطلاب"])},
    "committeesPlanList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات خطة اللجان"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرس"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الوقت"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الوقت"])},
    "role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البند"])},
    "student-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الطالبــ/ـة"])},
    "parent_relation_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب صلة القرابة"])}
  },
  "driver": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائقين"])},
    "add_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة سائق"])},
    "edit_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل سائق"])},
    "driversList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات السائقين"])},
    "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم السائق"])},
    "driver_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم السائق"])},
    "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رقم التلفون"])},
    "driver_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل رقم التلفون"])},
    "driver_home_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الثابت"])},
    "driver_home_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الثابت"])},
    "driver_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" العمر"])},
    "driver_age_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل العمر"])},
    "driver_martial_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحالة الاجتماعية"])},
    "driver_martial_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الحالة الاجتماعية"])},
    "driver_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل"])},
    "driver_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب الايميل"])},
    "card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الهوية"])},
    "card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
    "card_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الهوية"])},
    "card_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مكان الاصدار"])},
    "card_issuance_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل مكان الاصدار"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
    "card_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاصدار "])}
  },
  "homework": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة واجب جديد"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الواجب"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الواجبات "])},
    "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد التسليم"])},
    "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التصحيح ؟"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الواجب"])},
    "correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصحيح الواجبات"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد الواجبات"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد واجب بهذا الاسم"])}
  },
  "bus": {
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباص"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباصات"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الباص"])},
    "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة باص"])},
    "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل باص"])},
    "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الباص"])},
    "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأستاذ"])},
    "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائق"])},
    "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الرحلة"])},
    "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل خط الرحلة"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الباص"])},
    "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نوع الباص"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الباص"])},
    "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الباص"])},
    "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
    "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل عدد المقاعد"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
    "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اللون"])},
    "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم اللوحة"])},
    "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم اللوحة"])}
  },
  "amount": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل المبلغ"])}
  },
  "student-bus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب مع الباصات"])},
    "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة باص"])},
    "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل باص"])},
    "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الباص"])},
    "bus_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم الباص"])},
    "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأستاذ"])},
    "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائق"])},
    "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الرحلة"])},
    "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل خط الرحلة"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الباص"])},
    "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نوع الباص"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الباص"])},
    "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الباص"])},
    "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
    "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل عدد المقاعد"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
    "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اللون"])},
    "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم اللوحة"])},
    "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم اللوحة"])},
    "add_student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الطلاب للباص"])},
    "chose_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الطلاب"])},
    "chose_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الباص"])},
    "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشرف"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])}
  },
  "errors": {
    "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نأسف !"])},
    "greater-than-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون القيمة اكبر من صفر"])},
    "maximum-attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجاوزت الحد الأقصى لمحاولة تسجيل الدخول, يرجى اعادة المحاولة لاحقاَ."])},
    "error-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يبدو انك غير متصل بقاعدة البيانات "])},
    "must-be-full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون الاسم رباعي"])},
    "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء لا يجب ان يقل عن تاريخ الاصدار"])},
    "issuer_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاصدار لا يجب ان يتجاوز تاريخ الانتهاء"])},
    "period_is_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هذه الفترة مستخدمة من قبل"])},
    "must-be-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب الموافقة على هذه الرسالة "])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب . "])},
    "max-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكد ان عدد الاشهر لا يتجاوز العدد المطلوب "])},
    "min-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكد ان عدد الاشهر لا يقل عن العدد المطلوب "])},
    "max-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكد ان الحقل لا يتجاوز الحد الاقصى للاحرف"])},
    "min-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكد ان الحقل لا يقل عن الحد الادنى للاحرف"])},
    "expire-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء العضوية يجب ان يكون اكبر من تاريخ اصدارها "])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" النص المدخل ليس عنوان ايميل صحيح "])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النص المدخل ليس عنوان URL صحيح  "])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال نص فقط "])},
    "arabic-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال احرف عربية فقط"])},
    "english-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال احرف انجليزية فقط"])},
    "numbers_and_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يحتوي الحقل على احرف"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال ارقام  فقط"])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال رقم صحيح"])},
    "int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارقام فقط"])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه القيمة موجدة بالفعل"])},
    "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البدايه اكبر من  وقت النهاية"])},
    "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النهاية اقل من  وقت البداية"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدايه اكبر من  تاريخ النهاية"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النهاية اقل من  تاريخ البداية"])},
    "start-equal-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداية لا يمكن ان يساوي تاريخ النهاية"])},
    "must-be-insert-seat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال ارقام جلوس اولا"])},
    "user-logged-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عذراً المستخدم مسجل دخول بالفعل"])},
    "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التسليم يجب ان يكون اكبر من تاريخ اليوم"])},
    "file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تسمية الملف غير مقبولة: فقط ارقام وحروف _ -"])},
    "invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ غير صحيح"])}
  },
  "teacher": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرس"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مدرس"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" معلومات المدرسين"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختار مدرس"])}
  },
  "month": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاشهر الدراسية"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر الشهر"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشهر"])},
    "add_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة شهر"])},
    "edit_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل شهر"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشهر"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الشهر"])},
    "cureent_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الحالي"])},
    "cureent_month_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك شهر حالي"])}
  },
  "day": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
    "gaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الايام"])},
    "day_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم اجازة"])},
    "add_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة يوم"])},
    "day_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم اليوم"])},
    "day_overload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن ان يكون هناك اكثر من سبعة ايام"])}
  },
  "calendar": {
    "semesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاترام"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل السنوات"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الايام"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الشهور"])}
  },
  "estimate": {
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظمة التقديرات"])},
    "add_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تقدير"])},
    "edit_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل التقدير"])},
    "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التقدير عربي"])},
    "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التقدير انجليزي "])},
    "degree_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلى درجة"])},
    "degree_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادنى درجة"])},
    "name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم التقدير عربي"])},
    "name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم التقدير انجليزي "])},
    "degree_max_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اعلى درجة"])},
    "degree_min_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل ادنى درجة"])},
    "degree_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايمكن ان تكون اعلى درجة اقل من ادنى درجة"])},
    "degree_error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايمكن ان تكون الدرجة الكبرى اقل من الدرجة الصغرى"])}
  },
  "student-activity": {
    "is-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشترك ؟"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد الطلاب المشاركين في النشاط"])},
    "max-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب المشاركين اكبر من العدد المطلوب لهذا النشاط"])},
    "student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشاركين"])}
  },
  "class-marks": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخال درجات الترم"])},
    "collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحصلة"])},
    "final-exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الامتحان النهائي"])},
    "mark_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المجموع"])}
  },
  "subject": {
    "for-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمادة "])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المادة"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المواد الدراسية"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اختر المادة"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم  عربي"])},
    "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المواد الدراسية"])},
    "subjects-study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الدراسية  "])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاسم  انجليزي"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المادة"])},
    "min-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدرجة الصغرى"])},
    "max-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة الكبرى"])},
    "add-to-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اضافة المادة الى المجموع الكلي ؟"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مواد "])},
    "not-found-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجدانشطة "])}
  },
  "weekly-schedule": {
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الاسبوعي"])},
    "add-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جدول اسبوعي جديد"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الجدول الاسبوعي"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جدول دراسي"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الجداول الدراسية"])},
    "schedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الاسبوعي"])}
  },
  "alert": {
    "failure": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ! فشل "])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في عملية الادخال"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في عملية التعديل"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في عملية الحذف"])},
      "exist-but-not-movable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطالب موجود ولاكن غير قابل للنقل"])},
      "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطالب غير موجود"])},
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه السجلات موجودة بالفعل"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في حفظ البيانات"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اتصال بقاعدة البيانات"])},
      "create-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في انشاء نسخة احتياطية "])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في استعادة النسخة الاحتياطية "])},
      "cannot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حذف هذا السجل لان هناك سجلات اخرى مرتبطة بة "])},
      "entry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الادخال غير صحيح"])},
      "changed-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في تعديل كلمة المرور "])},
      "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في استعادة كلمة المرور الافتراضية"])}
    },
    "attention": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبية"])},
      "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك سنة حالية"])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سيتم استعادة اخر نسخة احنياطية تم انشائها ! "])},
      "delete-talent-with-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سيتم حذف هذه المهارة من سجلات الطلاب ! "])},
      "delete-supervisor-with-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم حذف هذه الجهة المشرفة من سجلات الدورات"])},
      "delete-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سيتم حذف جميع السجلات التالية المرتبطة بهذا السجل :"])},
      "more-regulars-than-registrants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المنتظمين اكبر من عدد المسجلين "])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح "])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الادخال بنجاح "])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التعديل بنجاح "])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف بنجاح "])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ البيانات بنجاح"])},
      "create-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم انشاء نسخة احتياطية بنجاح"])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تم استعادة النسخة الاحتياطية بنجاح"])},
      "changed-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل كلمة المرور بنجاح"])},
      "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استعادة كلمة المرور الافتراضية بنجاح"])}
    },
    "not-found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد 404 "])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد سجل مع هذه البيانات "])}
    },
    "warnning": {
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بما أن هذه هي المرة الأولى التي تقوم فيها بتسجيل الدخول أو أنك لا تزال تستخدم كلمة المرور الافتراضية, نرجو منك تغيير كلمة المرور الخاصة بك لضمان أمان حسابك."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم التعديل على هذا السجل"])},
      "suggest-insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد انشاء سجل جديد ؟"])}
    }
  },
  "export": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF تصدير ملف "])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXCEL تصدير ملف "])},
    "cvs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV تصدير ملف "])},
    "error-loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في تحميل البيانات"])}
  },
  "import": {
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استيراد"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF استيراد ملف "])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXCEL استيراد ملف "])},
    "cvs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV استيراد ملف "])},
    "import-records-to-db": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع السجلات الى قاعدة البيانات"])}
  },
  "libraries": {
    "libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبات"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك في نظام المكتبات"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم "])},
    "mname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأب"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب "])},
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعضاء "])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤلفين "])},
    "cs-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر صورة "])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طالب"])},
    "instructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلم"])},
    "external-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عضو خارجي"])},
    "type-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نوع العضو"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  نشط"])},
    "release-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  مكان الإصدار"])},
    "release-place-placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل مكان الإصدار"])},
    "release-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تاريخ الإصدار"])},
    "expire-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تاريخ الإنتهاء"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "mng-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الكتب"])},
    "mng-fines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة الغرامات"])},
    "issue-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعارة كتاب"])},
    "issue-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعارة الكتب"])},
    "return-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم كتاب"])},
    "all-issued-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الكتب المستعارة"])},
    "all-archived-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الكتب المؤرشفة"])},
    "mng-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المستخدمين"])},
    "all-request-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الكتب المطلوبة"])},
    "institution-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموسسة"])},
    "other-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات اخرى"])},
    "report-bugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الأخطاء"])},
    "about-software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول البرنامج"])},
    "change-passowrd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل كلمة المرور"])},
    "update-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل التفاصيل"])},
    "add-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة كتاب"])},
    "all-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الكتب"])},
    "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مستخدم"])},
    "all-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المستخدمين"])},
    "book": {
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتب"])},
      "book-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات الكتب"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الكتاب"])},
      "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الكتاب"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الكتاب"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الكتاب"])},
      "page-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الصفحات"])},
      "publication-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النشر"])},
      "total-copys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد النسخ"])}
    },
    "language": {
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اللغة"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اللغة"])}
    },
    "category": {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تصنيف"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء تصنيف"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التصنيف"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التصنيف"])}
    },
    "country": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الدولة"])}
    },
    "city": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدينة"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المدينة"])}
    },
    "department": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القسم"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القسم"])}
    },
    "authors": {
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤلف"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المؤلف"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مؤلف"])}
    },
    "publisher": {
      "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دار النشر"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم دار النشر"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر دار النشر"])}
    },
    "nationality": {
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجنسية"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الجنسية"])}
    }
  },
  "monthly_marks": {
    "marks-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة درجات الشهر"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
    "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شفهي"])},
    "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواظبة"])},
    "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحريري"])},
    "estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ممتاز"])},
    "very_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيد جدا"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيد"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقبول"])},
    "weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضعيف"])},
    "statistics_by_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات بناء على شهر "])},
    "statistics_by_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات بناء على ترم "])},
    "statistics_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات بناء على سنة"])},
    "count_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
    "count_of_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الذكور"])},
    "count_of_fmale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاناث"])},
    "count_of_passed_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب الناجحين    "])},
    "count_of_failed_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب الراسبين"])},
    "precent_of_passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة النجاح"])},
    "precent_of_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الرسوب"])},
    "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب"])}
  },
  "fees": {
    "discount_is_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الخصم غير صحيح"])},
    "add_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة رسوم"])},
    "add_fees_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة رسوم الطالب"])},
    "edit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل رسوم "])},
    "edit_fees_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل رسوم الطالب"])},
    "add_student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة رسوم للطالب"])},
    "update_student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل رسوم الطالب"])},
    "installments_number_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الاقساط"])},
    "installments_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاقساط"])},
    "days_between_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الايام بين اللاقساط"])},
    "days_between_installments_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الايام"])},
    "installment_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع قسط طالب"])},
    "installment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ القسط"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
    "installment_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
    "installment_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي"])},
    "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدفع"])},
    "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع قسط"])},
    "show_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المدفوعات"])},
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع قسط"])},
    "who_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع بواسطة"])},
    "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المدفوع"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المطلوب"])}
  },
  "summer": {
    "yard-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجود ساحة"])},
    "lecture-room-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر قاعة محاضرات"])},
    "accoustics-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر الصوتيات"])},
    "electricity-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر الكهرباء/ الطاقة"])},
    "activity-tools-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر أدوات أنشطة رياضية"])},
    "shortage-of-worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عجز العاملين"])},
    "distribute-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة توزيع الجوائز والهدايا"])},
    "supply-dispensing-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة توزيع المستلزمات"])},
    "open-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات المفتوحة"])},
    "typical-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات النموذجية"])},
    "eighth-quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرآن ثمن"])},
    "quarter-quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرآن ربع"])},
    "half-quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرآن نصف"])},
    "seals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميداليات"])},
    "sacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكياس"])},
    "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رايات"])},
    "big-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رايات كبير"])},
    "medium-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رايات وسط"])},
    "scarfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وشاحات"])},
    "gifts-prizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدايا والجوائز"])},
    "teachers-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المدرسين"])},
    "male-students-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد الطلاب"])},
    "female-students-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد الطالبات"])},
    "students-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد الطلاب"])},
    "collaborators-for-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتعاونين للمدارس"])},
    "male-teachers-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المدرسين"])},
    "female-teachers-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المدرسات"])},
    "football-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد كور القدم"])},
    "football-net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكات كرات القدم"])},
    "volleyball-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد كور الطائرة"])},
    "volleyball-net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكات كور الطائرة"])},
    "headbands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الربطات"])},
    "sports-supplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلزمات الرياضية"])},
    "activity-groups-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات جماعة الأنشطة"])},
    "records-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السجلات والتراخيص"])},
    "curriculums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج"])},
    "open-school-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات مدارس مفتوحة"])},
    "open-school-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تراخيص مدارس مفتوحة"])},
    "open-school-supplement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملحق مدارس مفتوحة"])},
    "typical-school-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات مدارس نموذجية"])},
    "typical-school-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تراخيص مدارس نموذجية"])},
    "typical-school-supplement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملحق مدارس نموذجية"])},
    "collaborator-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المتعاونين والمتعاونات"])},
    "male-collaborator-certificates-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المتعاونين بالمديريات"])},
    "female-collaborator-certificates-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المتعاونات بالمديريات"])},
    "male-collaborator-certificates-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المتعاونين بالمحافظات"])},
    "female-collaborator-certificates-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهائد المتعاونات بالمحافظات"])},
    "dropout-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المتسربين"])},
    "registered-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المسجلين"])},
    "regular-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المنتظمين"])},
    "dropout-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المتسربين"])},
    "registered-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدارس المسجلة "])},
    "serial-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم التسلسلي"])},
    "overbooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزيادة"])},
    "issued-for-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنصرف للطلاب"])},
    "issued-for-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنصرف للموظفين"])},
    "received-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استلامه"])},
    "lectures-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحصص"])},
    "students-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الطلاب"])},
    "employees-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الموظفين"])},
    "activities-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الأنشطة المنفذة"])},
    "visits-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الزيارات المنفذة"])},
    "tasks-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المهام الادارية المنفذة"])},
    "address-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الدورة الصيفية"])},
    "summer-open-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الدورات المفتوحة"])},
    "summer-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدورات الصيفية المفتوحة"])},
    "summer-open-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الدورات المفتوحة"])},
    "fundation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التمويل"])},
    "funded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ممولة"])},
    "not-funded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" غير ممولة"])},
    "is-funded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المدرسة ممولة ؟"])},
    "is-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل المدرسة معتمدة"])},
    "is-emmployee-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل العامل معتمد"])},
    "supplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلزمات"])},
    "school-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الدورات الصيفية"])},
    "school-statistics-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير احصائيات الدورات الصيفية"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد دورة صيفية"])},
    "expelled-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المفصولين بسبب الغياب"])},
    "students-drop-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسرب الطلاب"])},
    "droped-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متسرب"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير الدورة الصيفية"])},
    "Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيان"])},
    "is-holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل  اجازة؟"])},
    "another-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات اخرى"])},
    "extended-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم استبعاد الطالب   بعد"])},
    "lesson-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدروس"])},
    "lesson-record-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدروس"])},
    "lesson-record-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اخذ الدرس"])},
    "work-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العمل"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة الشخصية"])},
    "not-found-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صورة"])},
    "add-supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الجهة المشرفة"])},
    "edit-supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الجهة المشرفة"])},
    "summer-camp-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض  الدورات الصيفية"])},
    "initialization-supervising-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الجهات المشرفة"])},
    "add-sup-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جهة مشرفة "])},
    "remove-sup-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف جهة مشرفة "])},
    "supervising-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الجهة المشرفة"])},
    "supervising-authority-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الجهة المشرفة"])},
    "supervising-authority-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجهة المشرفة"])},
    "supervision-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاشراف"])},
    "solitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القرية/العزلة"])},
    "not-found-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات"])},
    "out-of-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اليوم خارج نطاق التقويم الدراسي"])},
    "is-holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم اجازة "])},
    "already-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط مسجل من قبل"])},
    "already-calender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ مسجل من قبل"])},
    "already-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة بالعربي مسجل من قبل"])},
    "already-country-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولةبالانجليزي مسجل من قبل"])},
    "already-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة بالعربي مسجل من قبل"])},
    "already-governorate-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة بالانجليزي مسجل من قبل"])},
    "already-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية بالعربي مسجل من قبل"])},
    "already-directorate-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية بالانجليزي مسجل من قبل"])},
    "already-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى مع المرحلة مسجل من قبل"])},
    "already-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الميلادية مسجلة من قبل"])},
    "already-year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الهجرية مسجلة من قبل"])},
    "already-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشعبة مسجل من قبل"])},
    "already-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زمز الشعبة مسجل من قبل"])},
    "assign-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات المعينة"])},
    "un-assign-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات غير المعينة"])},
    "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الصورة يجب ان يكون"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترات"])},
    "school_summer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدرسة الصيفية"])},
    "school_summer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المدرسة الصيفية"])},
    "work_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدوام"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
    "employee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العاملين"])},
    "employee_avaliable_by_responsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر العاملين حسب المسئوليات"])},
    "plan_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة خطة النشاط"])},
    "recived-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الكتب المستلمة"])},
    "member_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العضو"])},
    "member_adject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهنة العضو"])},
    "member_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة التي يعمل فيها"])},
    "member_phonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "enter_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الملاحظات"])},
    "bignner-perform-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية تنفيذ الخطة"])},
    "end-perform-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية تنفيذ الخطة"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "plan_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخطة"])},
    "plan_describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف خطة النشاط"])},
    "plan_describe_write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب وصف خطة النشاط"])},
    "class_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحصة "])},
    "date-activity-opreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ تنفيذ النشاط"])},
    "date-activity-opreation_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء النشاط"])},
    "is_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل انتهى"])},
    "is_run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل نفذ"])},
    "add-commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة لجان"])},
    "purpose-of-commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغرض من اللجنة"])},
    "type-of-commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع  اللجنة"])},
    "accommodation-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع السكن في المدرسة"])},
    "summer-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدورة الصيفية"])},
    "summer-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدارس النموذجية"])},
    "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المديريات"])},
    "add-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المديرية"])},
    "initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبادرات"])},
    "add-an-initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مبادرة"])},
    "edit-an-initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مبادرة"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستويات"])},
    "assigning-division-to-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسناد شعب لمستويات"])},
    "edit-assigning-division-to-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل اسناد شعب لمستويات"])},
    "add-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الايام"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأيام"])},
    "weekly-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسابيع"])},
    "academic-calendar-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم الدراسي"])},
    "add-holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجازات والمناسبات"])},
    "add-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط"])},
    "view-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الانشطة"])},
    "initialize-types-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الانشطة"])},
    "add-types-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة انواع الانشطة"])},
    "initialize-activity-processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنفيذ الانشطة"])},
    "add-activity-processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنفيذ الانشطة"])},
    "add-activity-plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطط الانشطة"])},
    "show-activity-plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطط الانشطة"])},
    "set-weekly-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الانشطة الاسبوعية"])},
    "assign-student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين طلاب للانشطة"])},
    "assign-commitiesmember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين اعضاء لجان"])},
    "showcommiteemember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض تعيين اعضاء للجان "])},
    "curricula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج"])},
    "receiving-curricula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم المناهج"])},
    "curriculum-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المناهج"])},
    "add-curriculum-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اضافة  المناهج المستلمة "])},
    "dropout-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة حالة الطالب"])},
    "move-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب الملتحقين"])},
    "dropout-reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسباب التسرب"])},
    "dropout-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب التسرب"])},
    "dropout-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التسرب"])},
    "assigning-students-initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين طلاب للمبادرات"])},
    "edit-assigning-students-initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل تعيين طلاب للمبادرات  "])},
    "manage-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة العاملين"])},
    "name-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العامل"])},
    "edit-assgin-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل اسناد المهارات للعاملين"])},
    "add-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة عامل"])},
    "manage-state-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة حالة العاملين"])},
    "state-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة حالة العاملين"])},
    "save-state-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حالة العاملين"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض العاملين"])},
    "initialization-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" العاملين"])},
    "add-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مهارات العاملين"])},
    "edit-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مهارات العاملين"])},
    "add-skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مهارات "])},
    "skill-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المهارة"])},
    "assign-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين مهارات للعاملين"])},
    "student-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواهب الطلاب"])},
    "add-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مواهب"])},
    "edit-talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الموهبه"])},
    "name-talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الموهبه"])},
    "assign-student-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين مواهب للطلاب"])},
    "edit-assign-student-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل تعيين مواهب للطلاب  "])},
    "add-teachers-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطة مدرسين"])},
    "view-teachers-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطة المدرسين"])},
    "teacher-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير العاملين"])},
    "initialize-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللجان"])},
    "add-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة لجان"])},
    "assign-committees-to-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين لجان تبع العاملين"])},
    "show-assign-committees-to-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  عرض تعيين اعضاء للجان  "])},
    "assign-committees-to-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["    تعيين اعضاء للجان "])},
    "study-lecture-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة الحصة الدراسية"])},
    "assign-study-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين الجدول الدراسي"])},
    "assign-weekly-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين الدرجات الاسبوعية"])},
    "study-schedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول دراسية"])},
    "add-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المحاضرات"])},
    "show-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المحاضرات"])},
    "name-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحاضرة"])},
    "add-visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة زائرين وجهات مساندة"])},
    "edit-visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل زائرين وجهات مساندة"])},
    "all-visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض زائرين وجهات مساندة"])},
    "visitors": {
      "name-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الزائر"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزوار"])},
      "adjective-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفة الزائر"])},
      "date-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الزيارة"])},
      "entity-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة الزائر"])},
      "evalu-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الزائر"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيارة"])},
      "dir-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة مساندة"])},
      "impression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانطباع"])},
      "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوصيات"])},
      "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم المدرسة للزيارة"])},
      "type-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الزائر"])},
      "occasion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناسبة"])}
    },
    "name-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الزائر"])},
    "weekly-follow-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة الاسبوعية"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الإحصاءات"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحصاءات"])},
    "add-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الإحصاء"])},
    "edit-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الإحصاء"])},
    "name-administrative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المهام الادارية"])},
    "type-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "is-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هو موجود"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المزود"])},
    "is-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تم التفعيل"])},
    "weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيات اسبوعية"])},
    "class-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفصول"])},
    "class-count-study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد فصول الدراسة"])},
    "add-class-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الفصول"])},
    "shift-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفتره"])},
    "place-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المكان"])},
    "yard_founded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجود ساحة للأنشطة"])},
    "place-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المكان"])},
    "place-name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المكان"])},
    "school-has-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدى الدورة الصيفية ترخيص"])},
    "school-has-garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدى الدورة الصيفية ساحة"])},
    "overall-assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم العام"])},
    "overall-assessment-for-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم العام للمكان (ملائم-غير ملائم)"])},
    "obtain-permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصول على تصريح(نعم-لا)"])},
    "add-overall-assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل التقييم العام"])},
    "quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص"])},
    "center_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدورة الصيفية "])},
    "center_name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الدورة الصيفية "])},
    "center_name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الدورة الصيفية بالانجليزي"])},
    "center_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدورة الصيفية انجليزي"])},
    "head_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم مدير الدورة الصيفية"])},
    "head_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل اسم مدير الدورة الصيفية"])},
    "summer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدورة الصيفية "])},
    "summer_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار  الدورة الصيفية"])},
    "summer_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع  الدورة الصيفية"])},
    "summer_place_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل موقع  الدورة الصيفية"])},
    "summer_placeholder_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل احداثيات  الدورة الصيفية"])},
    "summer_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" احداثيات  الدورة الصيفية"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يبدأ من"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينتهي في"])},
    "initiative": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المبادرة"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف المبادرة"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التنفيذ"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المشرف"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملاحظة"])}
    },
    "add-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مادة"])},
    "edit-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مادة"])},
    "add-type-activiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أنواع النشاط"])},
    "edit-activiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل النشاط"])},
    "level": {
      "phase-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المرحلة"])},
      "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرحلة"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستوى"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستوى انجليزي"])},
      "edit-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المستوى"])},
      "add-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المستوى"])},
      "please-select-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار الشعب"])},
      "the-division-assigned-to-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب المسنده للمستوى"])},
      "the-division-is-not-assigned-to-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب الغير مسنده لمستوى"])}
    },
    "activity": {
      "excuted-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة المنفذة"])},
      "activity_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات النشاط"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع النشاط"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
      "excuted-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التنفيذ"])},
      "gendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة المشاركة"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البدأ"])},
      "statrt_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وقت بداية النشاط"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتهاء"])},
      "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل وقت نهاية النشاط"])},
      "employee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العامل المسؤول"])},
      "employee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل اسم العامل المسؤول"])},
      "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل موقع النشاط"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع النشاط"])},
      "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم للمستلزمات"])},
      "evaluation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل تقييم النشاط "])},
      "evaluation_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم النشاط "])},
      "dificult_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصعوبات التي واجهت تنفيذ النشاط"])},
      "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المشاركين"])},
      "activtity_assigin_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التعيين"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة"])},
      "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط جديد"])},
      "add_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحل"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر النشاط"])},
      "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤول النشاط"])},
      "desc-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف نوع النشاط"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط رياضي"])},
      "cultural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط ثقافي"])},
      "entertaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط ترفيهي"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم النشاط"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكان"])},
      "placeholder": {
        "student-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المشاركين بالنشاط"])}
      },
      "difficultie_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل صعوبة "])},
      "solutions_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل حل"])},
      "difficultie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصعوبة"])},
      "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحل"])},
      "difficulties_and_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصعوبات والحلول"])},
      "difficulties_and_solutions_assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعيين الصعوبات والحلول"])},
      "difficulties_and_solutions_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض الصعوبات والحلول "])},
      "show_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الانشطة"])}
    },
    "calendar": {
      "add-academic-weeks-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بيانات الاسابيع الدراسية"])},
      "edit-academic-weeks-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بيانات الاسابيع الدراسية"])},
      "start-date-of-the-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد بدء الاسبوع"])},
      "end-date-of-the-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد نهاية الاسبوع"])},
      "is-it-the-current-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسبوع الحالي"])},
      "week-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاسابيع"])},
      "week-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الاسبوع"])},
      "academic-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الدراسية"])},
      "add-vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بيانات الاجازات و المناسبات"])},
      "edit-vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بيانات الاجازات و المناسبات"])},
      "study-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم الدراسي"])},
      "day-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اليوم"])},
      "duration-of-one-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال سبعة ايام"])},
      "select-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الاسبوع"])},
      "add-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تقويم دراسي"])},
      "hijri-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ الهجري"])},
      "gregorian-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ الميلادي"])},
      "saturday-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم الدراسي لا يبداء من يوم السبت ولذلك سوف يكون الاسبوع الاول اقل من سبعة ايام"])},
      "calendar-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم تهيئة التقويم وسيتم حذف الاجازات المدخلة"])},
      "calendar-update-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تعديل التقويل بسبب وجود بعض البيانات المرتبطة فيه"])},
      "day-init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون الايام مهيئة"])},
      "year-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تهيئة السنوات الدراسية او تسجيل الدخول مرة اخرى"])}
    },
    "address": {
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العناوين"])},
      "add-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة عنوان"])},
      "edit-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل عنوان"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العنوان"])}
    },
    "student": {
      "enter-academic-num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الرقم الأكاديمي للطالب"])},
      "recording-student-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب"])}
    },
    "lecture": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحاضرة"])}
    },
    "marks": {
      "quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قران كريم"])},
      "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنهج"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
      "ehsan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحسان"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانضباط"])}
    },
    "employee": {
      "unique-job-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوظيفي موجود بافعل"])},
      "place-of-work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة العمل"])},
      "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الموبايل"])},
      "children-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الأولاد"])},
      "add-employees-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بيانات العامل"])},
      "edit-employees-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بيانات العامل"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة العامل"])},
      "other-work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمل الاخر"])},
      "birth-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الميلاد"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير العاملين"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العامل"])},
      "date-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريح التحضير"])},
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الموظف موجود بالفعل"])}
    },
    "recommendations": {
      "add-weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الايجابيات/السلبيات"])},
      "title-weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عنوان الايجابيات/السلبيات"])},
      "edit-weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الايجابيات والسلبيات"])},
      "is-optimastic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هو إيجابي؟"])},
      "optimastic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيجابي"])},
      "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحلول"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الايجابيات"])},
      "address-negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان السلبيات"])},
      "implementation-officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤول التنفيذ"])},
      "name-implementation-officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم مسؤول التنفيذ"])}
    },
    "year": {
      "placeholder": {
        "year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 1444 "])},
        "year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 2023  "])}
      }
    },
    "curriculum": {
      "reciever_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المستلم"])},
      "reciever_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستلم"])},
      "recieved?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلم؟"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسين"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])}
    },
    "user-with-this-email-already-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل محجوز لمستخدم اخر"])},
    "prime-minister-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رئاسة الوزراء"])},
    "supreme-committee-for-activities-and-summer-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللجنة العليا للأنشطة و الدورات الصيفية"])},
    "general-administration-for-summer-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدارة العامة للدورات الصيفية"])},
    "report": {
      "school-summer-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة سير عمليات المراكز"])},
      "talented-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بالطلاب الموهوبين"])},
      "feeding-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقرير المالي للمحافظة"])},
      "feeding-report-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقرير المالي للمحافظة اعتمادا على التاريخ"])},
      "events-and-occasions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اجازات الفعاليات والمناسبات"])},
      "employee-attendance-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حضور وغياب العاملين النهائي"])},
      "employee-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حضور وغياب العاملين"])},
      "event-or-occasion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعالية او المناسبة"])},
      "fullname-with-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم رباعياً مع اللقب"])},
      "qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤهل الدراسي"])},
      "work-on-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمله بالمدرسة الصيفية"])},
      "skills-he-is-good-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات التي يجيدها"])},
      "total-attendance-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي ايام الحضور"])},
      "total-absence-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي ايام الغياب"])},
      "school-visit-impact-assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم إدارة الأثر للزيارة"])},
      "visitors-with-evaluation-and-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزائرين مع التقييم والتوصيات"])},
      "impression-about-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انطباعة حول المدرسة"])},
      "administrative-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام الادارية"])},
      "write-yes-or-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم او لا"])},
      "do-or-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تم تطبيقها"])},
      "task-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المهمه"])},
      "positives-negatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايجابيات و السلبيات"])},
      "schedule-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات عدد الحصص"])}
    }
  },
  "settings": {
    "system-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات النظام"])},
    "school-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستخدام نظام المدرسة"])},
    "summer-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستخدام الدورة الصيفية الصيفي"])}
  },
  "summer-report": {
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
    "school-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة"])},
    "general-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير عامة"])},
    "approach-statistics-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المناهج  "])},
    "material-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستلزمات"])},
    "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستلزمات"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
    "employee-available-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر العاملين  "])},
    "school-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تقرير التقويم الدراسي"])},
    "occasion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اجازات المناسبات والفعاليات"])},
    "students-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلاب "])},
    "personal-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية"])},
    "student-personal-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلاب الشخصية"])},
    "teacher-personal-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المعلمين الشخصية"])},
    "students-talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواهب الطلاب"])},
    "walkout-students-by-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسرب الطلاب  "])},
    "students-statistics-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب "])},
    "students-marks-sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الطلاب"])},
    "lectures-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول الحصص"])},
    "weekly-lectures-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجداول الاسبوعية"])},
    "teachers-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير المعلمين"])},
    "employee-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حضور وغياب العاملين"])},
    "visits-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الزيارات"])},
    "initiatives-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير المبادرات"])},
    "initiatives-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاركين بالمبادرات"])},
    "activity-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانشطة"])},
    "summer-center-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشطة الدورة الصيفية "])},
    "weekly-activity-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الاسبوعي"])},
    "weeekly-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الاسبوعية"])},
    "workers-displine-level-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى انضباط العاملين النهائي"])},
    "workers-displine-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى انضباط العاملين"])},
    "students-count-statistic-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب النهائية"])},
    "students-count-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب"])},
    "student-statistics-by-leaking-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الطلاب المتسربين النهائية"])},
    "student-statistics-by-leaking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الطلاب المتسربين"])},
    "curreculam-statistics-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المناهج  "])},
    "lectures-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الحصص النهائية"])},
    "lectures-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الحصص"])},
    "actiivty-excution-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات تنفيذ الانشطة النهائية"])},
    "actiivty-excution-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات تنفيذ الانشطة"])},
    "supporting-entity-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الجهات المساندة النهائية"])},
    "supporting-entity-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الجهات المساندة"])},
    "management-tasks-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المهام الادارية النهائية"])},
    "management-tasks-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المهام الادارية"])},
    "supplies-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستلزمات"])},
    "positives-negatives-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الايجابيات والسلبيات النهائية"])},
    "positives-negatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   الايجابيات والسلبيات"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور والغياب"])},
    "final-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير النهائية"])},
    "summer-center-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات  الدورة الصيفية"])},
    "teachers-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المعلمين النهائية"])},
    "teachers-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات العاملين"])},
    "curreculam-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المناهج النهائية"])},
    "curreculam-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المناهج"])},
    "supplies-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستلزمات"])},
    "activity-participated-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشتركين "])},
    "activity-full-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيانات النشاط "])},
    "daily-check-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة يومية"])},
    "students-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حضوروغياب الطلاب"])},
    "teachers-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حضوروغياب العاملين"])},
    "committee-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعضاء الجان"])},
    "committee-members-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير أعضاء اللجان"])}
  },
  "summer-sidebar": {
    "visits-carried-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقرير بالزيارات المنفذة"])},
    "roles-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة ادوار المستخدمين"])},
    "review-summer-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة بيانات الدورات الصيفية"])},
    "review-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة سجلات الموظفين"])},
    "user-input-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير بمدخلات المستخدمين"])},
    "distribute-supplies-gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع المستلزمات والهدايا"])},
    "licenses-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التراخيص والسجلات"])},
    "gifts-prizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدايا والجوائز"])},
    "phases-cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطائق للمستويات"])},
    "levels-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج للمستويات"])},
    "sport-accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلزمات الرياضية"])},
    "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهائد"])},
    "add-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة احصائيات"])},
    "dropedout-froms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسرب من الاستمارات المدخلة"])},
    "mobile-apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيقات الموبايل"])},
    "upload-apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع التطبيقات"])},
    "employees-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الموظفين"])},
    "circulars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعميمات"])},
    "mobile-versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصدارات تطبيق الموبايل"])},
    "dropout-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المتسربين"])},
    "dropout-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المتسربين"])},
    "statistics-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقارير "])},
    "talented-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب الموهوبين"])},
    "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل العمليات"])},
    "feeding-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقرير المالي للمحافظة"])},
    "feeding-report-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقرير المالي للمحافظة حسب التاريخ "])},
    "attendnace-protoflio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حافظة التحضير"])},
    "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزائرين"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدروس"])},
    "activities-intiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانشطة والمبادرات"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة"])},
    "intiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبادرات"])},
    "community-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجان المجتمع"])},
    "study-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجداول الدراسية"])},
    "init-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الفترات"])},
    "add-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الجدول الدراسي "])},
    "edit-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل جدول دراسي"])},
    "personnel-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هيئة العاملين"])},
    "init-student-talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مواهب الطلاب"])},
    "init-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مهارات العاملين"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحصائيات"])},
    "managament-task-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المهام الادارية"])},
    "managament-task-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المهام الادارية"])},
    "managament-task-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المهام الادارية"])},
    "supplies-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم المستلزمات"])},
    "supplies-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اضافة المستلزمات"])},
    "supplies-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل المستلزمات"])},
    "supplies-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المستلزمات"])},
    "positives-negatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الايجابيات والسلبيات"])},
    "received-currical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج المستلمة"])},
    "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات "])},
    "weekly-marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الاسبوعية"])}
  },
  "request": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإستجابة"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 408 انتهاء زمن معالجة الطلب "])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])}
  },
  "models": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
    "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
    "LectureLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدروس"])},
    "ManagmentTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المهام  "])},
    "EmployeeAttendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الموظفين"])},
    "UserAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحية المستخدم"])},
    "StudentAttendanceSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب "])},
    "StudentSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات للطلاب"])},
    "SchoolSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات  الدورة الصيفية"])},
    "Employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات للعاملين"])},
    "Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التقويم الدراسي"])},
    "LectureSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الحصص الدراسية "])},
    "SchoolWeeklyScheduleSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الجداول الدراسية "])},
    "ActivitySummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الانشطة "])},
    "EmployeeLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات العاملين "])},
    "StudentActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات انشطة الطلاب "])},
    "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الاسابيع الدراسية "])},
    "MarkSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات درجات الطلاب"])},
    "LevelDivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات شعب المسنويات"])},
    "Approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المناهج"])},
    "ParentSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات اولياء الامور "])},
    "ContactSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التواصل "])},
    "DivisionSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الشعب الدراسية"])},
    "DaySummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الايام الدراسية"])},
    "ScheduleSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الفترات الزمنية "])},
    "ActivityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات انواع الانشطة "])},
    "SubjectSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المواد الدراسية"])},
    "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المستويات الدراسية "])},
    "Skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المهارات "])},
    "LevelStudentYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات السنوات الدراسية للطلاب "])},
    "Week,": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الاسابيع الدراسية"])},
    "ActivityOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات تنفيذ الانشطة "])},
    "Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الخطط الدراسية "])},
    "Talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المواهب "])},
    "StudentTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات مواهب الطلاب "])},
    "Initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المبادرات "])},
    "Visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الزوار "])},
    "Commitee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات اللجان الاجتماعية "])},
    "CommiteeMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات اعضاء اللجان "])},
    "Statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الاحصائيات"])},
    "Recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التوصيات"])},
    "SchoolWeeklyActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الجداول الدراسية "])},
    "ActivityDifficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات صعوبات الانشطة "])},
    "Vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات العطل الرسمية "])},
    "TeacherSubjectSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات مواد المدرسين "])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات "])},
    "Directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات "])},
    "lecturelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدروس"])},
    "managmenttask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المهام  "])},
    "employeeattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الموظفين"])},
    "userauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحية المستخدم"])},
    "studentattendancesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب "])},
    "studentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات للطلاب"])},
    "schoolsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات  الدورة الصيفية"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات للعاملين"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التقويم الدراسي"])},
    "lecturesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الحصص الدراسية "])},
    "schoolweeklyschedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الجداول الدراسية "])},
    "activitysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الانشطة "])},
    "employeelevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات العاملين "])},
    "studentactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات انشطة الطلاب "])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الاسابيع الدراسية "])},
    "marksummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات درجات الطلاب"])},
    "leveldivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات شعب المسنويات"])},
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المناهج"])},
    "parentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات اولياء الامور "])},
    "contactsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التواصل "])},
    "divisionsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الشعب الدراسية"])},
    "daysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الايام الدراسية"])},
    "schedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الفترات الزمنية "])},
    "activitytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات انواع الانشطة "])},
    "subjectsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المواد الدراسية"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المستويات الدراسية "])},
    "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المهارات "])},
    "levelstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات السنوات الدراسية للطلاب "])},
    "week,": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الاسابيع الدراسية"])},
    "activityoperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات تنفيذ الانشطة "])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الخطط الدراسية "])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المواهب "])},
    "studenttalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات مواهب الطلاب "])},
    "initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات المبادرات "])},
    "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الزوار "])},
    "commitee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات اللجان الاجتماعية "])},
    "commiteemember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات اعضاء اللجان "])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الاحصائيات"])},
    "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التوصيات"])},
    "schoolweeklyactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات الجداول الدراسية "])},
    "activitydifficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات صعوبات الانشطة "])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات العطل الرسمية "])},
    "teacherSubjectSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات مواد المدرسين "])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات "])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات "])},
    "LevelStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستويات"])},
    "levelstatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستويات"])}
  },
  "reportpublic": {
    "daily_summer_operations_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير عمليات الدورات الصيفية اليومي"])},
    "the_total_number_student_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجمالي عدد الطلاب والموظفين في المراكز الصيفية"])},
    "total_number_student_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجمالي عدد الطلاب والموظفين"])},
    "summer_center_student_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات طلاب المركز الصيفي"])},
    "summer_center_employee_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات موظفين المركز الصيفي"])},
    "data-student-employee-present-actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلاب والعاملين الحاضرين والفعلي"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموظفين"])},
    "summer_course_statistics_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير احصائيات الدورات الصيفية"])},
    "TotalWorkersStudentsCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية-العاملين-الطلاب"])},
    "CentersOWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعداد الدورات الصيفية المفتوحة-العاملين-الطلاب"])},
    "NoInternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايوجد اتصال با الانترنت"])},
    "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلاب"])},
    "summer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدورة الصيفية"])},
    "statistics-types-tenters-students-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات بأنواع (الدورات الصيفية-الطلاب-العاملين) لكل نوع"])},
    "Total-types-tenters-students-type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي  الدورات الصيفية"])},
    "CenterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسم الدورة الصيفية"])},
    "number-positions-each-TOC-typical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الدورات الصيفية لكل نوع ( مفتوحة - نموذجية )"])},
    "parent_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التلفون"])},
    "job-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الوظيفة"])},
    "centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية"])},
    "name-worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العامل"])},
    "TotalSummation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "WorkerStatisticsForEachCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات العمال لكل دورة صيفية"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسة"])},
    "student-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب:"])},
    "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطالب"])},
    "StudentStatisticsForAllCenters2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الطلاب لكل الدورات"])},
    "StudentStatisticsForAllCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الطلاب لكل دورة صيفية"])},
    "StatisticsOfAllSummerCentersInTheDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات كل الدورات الصيفية  بالمديرية"])},
    "StatisticsOfAllSummerCentersInTheDistrictsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الدورات الصيفية "])},
    "StatisticsOfAllCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات كل الدورات الصيفية"])},
    "TotalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الزيارات"])},
    "WorkerStatisticsForEachCenter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجمالي العمال لكل دورة صيفية"])},
    "governorate1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
    "VisitsCarriedOutDuringTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزيارات المنفذة خلال يوم محدد"])},
    "visits-carried-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزيارات المنفذة"])},
    "VisitsCarriedOutDuringTheDay2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزيارات المنفذة  خلال اليوم"])},
    "StatisticsTypeMaleFemaleSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحصائيات بحسب نوع الدورات (الذكور - الإناث - العامة)"])},
    "StatisticsTypeMaleFemaleSchools2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحصائيات بحسب نوع الدورات الصيفية (الذكور والإناث)"])},
    "SummaryTotalOpenPositionMFDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية الدورات الصيفية المفتوحة (ذكور، إناث)"])},
    "SummaryTotalOpenPositionMFDistrict2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية المفتوحة (ذكور، إناث)"])},
    "SummaryTotalOpenPositionMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية الدورات الصيفية المفتوحة (ذكور، إناث)"])},
    "SummaryTotalClosePositionMFDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية الدورات الصيفية المغلقة (ذكور، إناث)"])},
    "SummaryTotalClosePositionMFDistrict2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية المغلقة (ذكور، إناث)"])},
    "SummaryTotalClosePositionMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية الدورات الصيفية المغلقة (ذكور، إناث)"])},
    "SummaryTotalTypicalPositionMFDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية الدورات الصيفية النموذجية (ذكور، إناث)"])},
    "SummaryTotalTypicalPositionMFDistrict2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية النموذجية (ذكور، إناث)"])},
    "SummaryTotalTypicalPositionMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية الدورات الصيفية النموذجية (ذكور، إناث)"])},
    "LevelsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسميات الصفوف"])},
    "NumberOfStudentsByLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعداد الطلاب بحسب المستويات"])},
    "TotalMFWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع العاملين العاملات"])},
    "NumberOfOpenCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الدورات الصيفية المفتوحة"])},
    "WorkingStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات العاملين"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرية"])},
    "GenerlSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية العام"])},
    "MalesSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية الذكور"])},
    "FemalesSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية الاناث"])},
    "NumberOfGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مديريات المحافظة"])},
    "TheTotalAreaOfTheGovernorateIsKm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي مساحة المحافظة كم"])},
    "SummerCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدورات الصيفية"])},
    "NumberOfStudentsAndWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العاملين والطلاب"])},
    "NumberOfWorkers2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العاملين"])},
    "NumberOfFWorkers2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العاملات"])},
    "NumberOfWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي العاملين"])},
    "TotalForStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجمالي للطلاب"])},
    "NumberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
    "NumberOfFStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطالبات"])},
    "TotalNumberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي عدد الطلاب"])},
    "TotalNumberOfFStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي عدد الطالبات"])},
    "TotalTypicalCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات النموذجية"])},
    "VariousActivitiesDuringTheSummerCentersPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة المختلفة خلال فترة الدورات الصيفية"])},
    "VariousActivitiesDuringTheSummerCentersPeriod2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة المنفذة للدورات الصيفية"])},
    "VariousActivitiesDuringTheSummerCentersPeriodInGovernorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة المختلفة خلال فترة الدورات الصيفية للمحافظات"])},
    "SummerCouresStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورات الصيفية"])},
    "ThePositionsForEachTypeAreTypicalOpenAndClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي عدد الدورات الصيفية في المحافظة"])},
    "TotalStudentsByLevelForCenters2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الطلاب حسب المستوى  للدورات"])},
    "TotalTypicalCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية النموذجية"])},
    "StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات  الدورات الصيفية بالمديريات في المحافظة"])},
    "StatisticsOfCerntersInDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورات الصيفية بالمديريات"])},
    "CentersForTheGovernorateAndItsAffiliates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية التابعة للمحافظة"])},
    "CentersForTheGovernorateAndItsAffiliatesTatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية للمديريات داخل المحافظة"])},
    "TotalSummaryOpenPositionsMFGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية للدورات الصيفية المفتوحة (ذكور، إناث)"])},
    "TotalSummaryOpenPositionsMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية للدورات الصيفية المفتوحة (ذكور، إناث)"])},
    "TotalSummaryClosePositionsMFGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية للدورات الصيفية المغلفة (ذكور، إناث)"])},
    "TotalSummaryClosePositionsMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية للدورات لصيفية المغلفة (ذكور، إناث)"])},
    "TotalStudentsByLevelForCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الطلاب حسب المستوى للدورات الصيفية"])},
    "TotalStudentsByLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الطلاب حسب المستوى"])},
    "TotalSummaryTypicalPositionsMFGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية للدورات الصيفية النموذجية (ذكور، إناث)"])},
    "TotalSummaryTypicalPositionsMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية للدورات الصيفية النموذجية (ذكور، إناث)"])},
    "SchoolStatisticsMFFirstReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورات الصيفية ذكور-اناث"])},
    "SchoolStatisticsMFSecondtReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الطلاب ذكور-اناث"])},
    "SchoolStatisticsTypeSchoolMFFirstReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورات الصيفية بحسب نوع الدورة الصيفية للذكور والاناث في المحافظة"])},
    "SchoolStatisticsTypeSchoolMFSecondReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورات الصيفية بحسب نوع الطلاب للذكور والاناث"])},
    "printCurriculum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة المنهج الدراسي"])},
    "TotalActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي من الأنشطة"])},
    "TotalActivitiesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الأنشطة"])},
    "TotalSchoolsWorkersStudentsByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية تفصيلية بعدد (الدورات الصيفية - العاملين - الطلاب) حسب المحافظة"])},
    "TotalOpenSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية المفتوحة"])},
    "TotalClosedSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية المغلقة"])},
    "TotalTypicalSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الصيفية النموذجية"])},
    "TotalClosedWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاملين (ذكور - إناث) مغلقة"])},
    "TotalOpenWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاملين (ذكور - إناث) مفتوحة"])},
    "TotalSchoolsMalesFemalesByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية تفصيلية بعدد الدورات الصيفية (ذكور - إناث) حسب المحافظة"])},
    "ClosedGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة عام"])},
    "ClosedFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة إناث"])},
    "ClosedMales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة ذكور"])},
    "OpenFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة إناث"])},
    "OpenMales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة ذكور"])},
    "OpenGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة عام"])},
    "TypicalFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذجية إناث"])},
    "TypicalMales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذجية ذكور"])},
    "TypicalGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذجية عام"])},
    "TotalWorkersMalesFemalesByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية تفصيلية بعدد العاملين (ذكور - إناث) حسب المحافظة"])},
    "TotalOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة إجمالي"])},
    "TotalClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة إجمالي"])},
    "TotalTypical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذجية إجمالي"])},
    "TotalStudentsMalesFemalesByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلاصة إجمالية تفصيلية بعدد الطلاب (ذكور - إناث) في المحافظات"])},
    "TotalMalesStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب (ذكور)"])},
    "TotalFemalesStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطالبات (إناث)"])},
    "TotalStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الطلاب"])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
    "NumberOfSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الدورات الصيفية"])},
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوحة"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة"])},
    "Typical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذجية"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عامة"])},
    "WorkersMalesFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاملين (ذكور - إناث)"])},
    "StudentsMalesFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب (ذكور - إناث)"])},
    "GrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجمالي الكلي"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجمالي"])},
    "Males": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكور"])},
    "Females": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إناث"])},
    "PrintedCurricula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج المستلمة للدورات الصيفية"])},
    "SummerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدورة الصيفية"])},
    "SummerTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الدورات الصيفية"])},
    "Workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عاملين/عاملات"])},
    "Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب/طالبات"])},
    "StatisticBySchoolType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحصائيات بحسب نوع المدرسة"])},
    "TotalSchoolsStaffStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية - العاملين - الطلاب"])},
    "Cloes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة"])},
    "TotalSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية"])},
    "TotalWorkrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي العاملين"])},
    "Totalstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الطلاب"])},
    "StudentsRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الطلاب"])},
    "WorkersRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة العمال"])},
    "SchoolsRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المدارس"])},
    "TotalTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المدرسين"])},
    "TeacherRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المدرسين"])},
    "Totalmartyrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي أبناء الشهداء"])},
    "martyrsRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة أبناء الشهداء"])},
    "ReportOnNumbersOfOpenSchoolsStaffAndStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير أعداد الدورات الصيفية المفتوحة والعاملين"])},
    "NumberOfDirectorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المديريات"])},
    "SummerSchoolsForTheYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدورات الصيفية للعام"])},
    "TheAverageNumberOfTeachersPerSchoolIs4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط عدد المدرسين لكل دورة صيفية"])},
    "Teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرسين"])},
    "NumberOfActivitiesOfficialsInTheGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مسؤولي الأنشطة في المحافضة"])},
    "ActivitiesInTheGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة في المحافضة"])},
    "ActivitiesInTheDirectorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة في المديرية"])},
    "ActivitiesInTheDirectorate2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة في  المديرية"])},
    "NumberOfPrincipalsOfOpenSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مديري الدورات الصيفية المفتوحة"])},
    "MalesWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاملين"])},
    "FemalesWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" العاملات"])},
    "Generl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
    "MaleStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب"])},
    "FemaleStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طالبات"])},
    "TotalOpenCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية المفتوحة"])},
    "TotalCloseCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية المغلقة"])},
    "TotalOpenAndClosedCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدورات الصيفية المفتوحة - النموذجية"])},
    "TotalNumberOfStudentsInGovernoratesByLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي عدد الطلاب في المحافظات (حسب المستوى)"])},
    "FirstQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأهيلي أول"])},
    "SecondQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأهيلي ثاني"])},
    "FirstBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أساسي أول"])},
    "SecondBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أساسي ثاني"])},
    "FirstAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أول متوسط"])},
    "SecondAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثاني متوسط"])},
    "FirstHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أول عالي"])},
    "SecondHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثاني عالي"])},
    "SummerTotalActivitiesCarriedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة المنفذة (من البداية إلى اليوم)"])},
    "SummerTotalActivitiesCarriedOutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات بالأنشطة المنفذة"])},
    "SummerTotalActivitiesCarriedOutDuringDayHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات بالأنشطة المنفذة خلال اليوم"])},
    "SummerTotalActivitiesCarriedOutDuringDayHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات  بالأنشطة المنفذة خلال اليوم"])},
    "Values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيم"])},
    "SummerWorkProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سير العمل في الدورات الصيفية"])},
    "TotalNumberOfActivitiesCarriedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الانشطة المنفذة إلى تاريخ اليوم"])},
    "ActiivtyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع النشاط"])},
    "OverallSummaryForNumberOfSchoolsre2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص شامل لعدد الدورات الصيفية (التقرير الثاني)"])},
    "SummerAllDetailedSummaryre2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المدارس والموظفين والطلاب(التقرير الثاني)"])},
    "SummerStatisticsBySchoolType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات بحسب نوع الدورة الصيفية"])},
    "OverallSummaryForNumberOfSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص شامل للدورات الصيفية"])},
    "StatisticsSchoolsAndStudentByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورات الصيفية والطلاب بحسب النوع"])},
    "SummerAllDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الدورات الصيفية والموظفين والطلاب"])},
    "SummerSchoolDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الدورات الصيفية "])},
    "SummerWorkerDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الموظفين في  الدورات الصيفية"])},
    "SummerStudentDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الطلاب في  الدورات الصيفية"])},
    "SummerActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الانشطة الصيفية"])},
    "Schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدارس"])},
    "InDirectorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في مديرية "])},
    "InGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في محافظة "])},
    "InTheGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في المحافظة "])},
    "InGovernorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في المحافظات "])},
    "ATotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي"])},
    "Authorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهات"])},
    "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "ChooseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار عنصر"])},
    "UsersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المستخدمين"])},
    "SchoolReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير الدورات الصيفية"])}
  },
  "statistics": {
    "statistics-pushed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفع الاحصائيات"])},
    "check-up-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة احصائيات الدورات الصيفية"])},
    "summer-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات  الدورة الصيفية"])},
    "center-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الدورة الصيفية"])},
    "levels-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات المستويات"])},
    "students-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الطلاب"])},
    "activities-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الانشطة"])},
    "male-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
    "female-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطالبات"])},
    "male-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد دورات البنين"])},
    "female-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد دورات البنات"])},
    "male-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدرسين"])},
    "female-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدرسات"])},
    "male-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العاملين الحاضرين"])},
    "female-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العاملات الحاضرات"])},
    "trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدربين"])},
    "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدراء"])},
    "activity-responsibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مسؤولي الانشطة"])},
    "martyrs-sons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد ابناء الشهداء"])},
    "martyrs-daughters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد بنات الشهداء"])},
    "SummerActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الانشطة الصيفية"])},
    "official-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الزيارات الرسمية"])},
    "supervision-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد زيارات المشرفين"])},
    "community-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد زيارات المجتمع"])},
    "committee-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد اعضاء اللجان"])},
    "recive-approch-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المناهج المسلمة"])},
    "activaty-group-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد جماعات الانشطة"])},
    "group-activaty-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد انشطة الجماعات"])},
    "activity-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الانشطة"])},
    "dir_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ احصائيات الدورات الصيفية الخاصة بهذه المديرية"])},
    "gov_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ احصائيات الدورات الصيفية الخاصة بهذه المحافظة"])},
    "all_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ الاحصائيات لكل الدورات الصيفية الصيفية"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزامنة"])},
    "no-need": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الدورة الصيفية لا تحتاج الى ادخال احصائياتها"])}
  },
  "log-entries": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])},
    "action-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النشاط"])},
    "content-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المحتوى"])},
    "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل النشاط"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية"])}
  }
}