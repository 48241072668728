import store from "@/store/index";
import OpenSchoolsWorkersStudents from "@/views/Report/PublicReport/OpenSchoolsWorkersStudents.vue";
import OpenCoursesClosedSchools from "@/views/Report/PublicReport/OpenCoursesClosedSchools.vue";
import TotalSchoolsEmployeesStudents from "@/views/Report/PublicReport/TotalSchoolsEmployeesStudents.vue";
import TotalOpenCenters from "@/views/Report/PublicReport/TotalOpenCenters.vue";
import TotalClosedCenters from "@/views/Report/PublicReport/TotalClosedCenters.vue";
import TotalNumberStudentsGovernoratesLevel from "@/views/Report/PublicReport/TotalNumberStudentsGovernoratesLevel.vue";
import SummerCenterStatistics from "@/views/Report/PublicReport/SummerCenterStatistics.vue";
import VariousActivitiesDuringSummerCentersPeriodCapital from "@/views/Report/PublicReport/VariousActivitiesDuringSummerCentersPeriodCapital.vue";
import StatisticsSummerCentersCapitalsMunicipalityDistricts from "@/views/Report/PublicReport/StatisticsSummerCentersCapitalsMunicipalityDistricts.vue";
import ClosedCentersInGovernorates from "@/views/Report/DailyReport/ClosedCentersInGovernorates";
import TypicalCenters from "@/views/Report/DailyReport/TypicalCenters";
import OpenCenters from "@/views/Report/DailyReport/OpenCenters";
import WorkingStaff from "@/views/Report/DailyReport/WorkingStaff";
import StatisticsTypeMaleFemaleTeacher from "@/views/Report/DailyReport/StatisticsTypeMaleFemaleTeacher.vue";
import NumberStudentsAccordingLevels from "@/views/Report/DailyReport/NumberStudentsAccordingLevels";
import VisitsCarriedOutDuringDay from "@/views/Report/DailyReport/VisitsCarriedOutDuringDay";
import VisitsCarriedOut from "@/views/Report/DailyReport/VisitsCarriedOut.vue";
import CentersGovernorateAffiliatedDirectorates from "@/views/Report/GovernorateReports/CentersGovernorateAffiliatedDirectorates";
import CentersTypeOpenClosedTypical from "@/views/Report/GovernorateReports/CentersTypeOpenClosedTypical";
import ActivitiesInGovernorate from "@/views/Report/GovernorateReports/ActivitiesInGovernorate";
import TotalSummaryOpenPositionsMFGovernorate from "@/views/Report/GovernorateReports/TotalSummaryOpenPositionsMFGovernorate";
import TotalSummaryClosedPositionsMFGovernorate from "@/views/Report/GovernorateReports/TotalSummaryClosedPositionsMFGovernorate.vue";
import TotalSummaryTypicalPositionsMFGovernorate from "@/views/Report/GovernorateReports/TotalSummaryTypicalPositionsMFGovernorate";
import GovernorateWorkingStaff from "@/views/Report/GovernorateReports/GovernorateWorkingStaff";
import SchoolStatisticsTypeSchoolMFFirstReport from "@/views/Report/GovernorateReports/SchoolStatisticsTypeSchoolMFFirstReport.vue";
import SchoolStatisticsTypeSchoolMFSecondReport from "@/views/Report/GovernorateReports/SchoolStatisticsTypeSchoolMFSecondReport.vue";
import ReportVisitsCarriedDuringDayGovernorates from "@/views/Report/GovernorateReports/ReportVisitsCarriedDuringDayGovernorates";
import CoursesForEachType from "@/views/Report/DirectorateReports/CoursesForEachType";
import ActivitiesDirectorates from "@/views/Report/DirectorateReports/ActivitiesDirectorates";
import StatisticsBySchoolsTypeStudentType from "@/views/Report/DirectorateReports/StatisticsBySchoolsTypeStudentType";
import OverallSummaryForOpenedCourses from "@/views/Report/DirectorateReports/OverallSummaryForOpenedCourses";
import OverallSummaryForClosedCourses from "@/views/Report/DirectorateReports/OverallSummaryForClosedCourses";
import OverallSummaryForTypicalCourses from "@/views/Report/DirectorateReports/OverallSummaryForTypicalCourses.vue";
import StudentByLevels from "@/views/Report/DirectorateReports/StudentByLevels";
import SummerCenterStatisticsDirectorates from "@/views/Report/DirectorateReports/SummerCenterStatisticsDirectorates.vue";
import WorkersSummer from "@/views/Report/DirectorateReports/WorkersSummer";
// import TotalNumberOfVisitorsCarriedOut from "@/views/Report/PublicReport/TotalNumberOfVisitorsCarriedOut.vue";


import SummerCurricula from "@/views/Report/PublicReport/SummerCurricula.vue";
import SummerAllDetailedSummary from "@/views/Report/PublicReport/SummerAllDetailedSummary.vue";
import SummerSchoolDetailedSummary from "@/views/Report/PublicReport/SummerSchoolDetailedSummary.vue";
import SummerStudentDetailedSummary from "@/views/Report/PublicReport/SummerStudentDetailedSummary.vue";
import SummerWorkerDetailedSummary from "@/views/Report/PublicReport/SummerWorkerDetailedSummary.vue";
import SummerActivities from "@/views/Report/PublicReport/SummerActivities.vue";

import TotalTypicalCenters from "@/views/Report/PublicReport/TotalTypicalCenters.vue";

import SummerTotalActivitiesCarriedOut from "@/views/Report/DailyReport/SummerTotalActivitiesCarriedOut";
import SummerWorkProgress from "@/views/Report/DailyReport/SummerWorkProgress";
import GovernorateStatisticBySchoolType from "@/views/Report/DailyReport/GovernorateStatisticBySchoolType";

import OverallSummaryForNumberOfSchools from "@/views/Report/GovernorateReports/OverallSummaryForNumberOfSchools";
import StatisticsSchoolsAndStudentByType from "@/views/Report/GovernorateReports/StatisticsSchoolsAndStudentByType";
import SummerStatisticsBySchoolType from "@/views/Report/GovernorateReports/SummerStatisticsBySchoolType";
import WorkflowInSummer from "@/views/Report/GovernorateReports/WorkflowInSummer";

import TotalNumberOfActivitiesCarriedOut from "@/views/Report/GovernorateReports/TotalNumberOfActivitiesCarriedOut.vue";
import VisitsCarriedOutDuringDayDirectorate from "@/views/Report/DirectorateReports/VisitsCarriedOutDuringDayDirectorate.vue";

import StudentsSummer from '@/views/Report/DirectorateReports/StudentsSummer'
import TalentedStudents from '@/views/Summer/reports/TalentedStudents.vue'
import DropoutStudents from '@/views/Summer/reports/DropoutStudents.vue'
import NumberStudentsAccordingLevelsGovernorates from '@/views/Report/GovernorateReports/NumberStudentsAccordingLevelsGovernorates'
import SummerCenterStatisticsGovernorate from '@/views/Report/GovernorateReports/SummerCenterStatisticsGovernorate.vue'
import OverallSummaryForNumberOfSchollsTable from '@/views/Report/GovernorateReports/OverallSummaryForNumberOfSchollsTable'
import SummerAllDetailedSummaryTable from '@/views/Report/PublicReport/SummerAllDetailedSummaryTable'
import TotalNumberOfActivitiesCarriedOutDuringDay from "@/views/Report/DirectorateReports/TotalNumberOfActivitiesCarriedOutDuringDay.vue";





// for statistics Directorate
import CoursesForEachTypeStatistics from '@/views/Report/statistics/Directorates/CoursesForEachType'
import ActivityDirectorateStatistics from '@/views/Report/statistics/Directorates/ActivitiesDirectorates.vue'
import StudentByLevelsStatistics from "@/views/Report/statistics/Directorates/StudentByLevelsStatistics.vue";
import TotalNumberOfActivitiesCarriedOutStatistics from "@/views/Report/statistics/Directorates/TotalNumberOfActivitiesCarriedOut";

import VisitorsDirectoratesStatistics from '@/views/Report/statistics/Directorates/VisitorsDirectorates'
// for statistics Gov
 import StudentByLevelsGovStatistics from "@/views/Report/statistics/Governorates/StudentByLevelsStatistics.vue";
// import ActivityGovStatistics from "@/views/Report/statistics/Governorates/TotalNumberOfActivitiesCarriedOut";
import VisitorsGovStatistics from '@/views/Report/statistics/Governorates/VisitorsGov'
// for statistics Public
import CoursesForEachTypePublicStatistics from '@/views/Report/statistics/CoursesForEachType'
import DataReportOfStudentAndStaffPresentAndActual from '@/views/Report/statistics/DataReportOfStudentAndStaffPresentAndActual'
import DialySummerOperationsReport from '@/views/Report/statistics/DialySummerOperationsReport'
import ActivitiesStatistics from '@/views/Report/statistics/Activities.vue'
import StudentByLevelsPublicStatistics from "@/views/Report/statistics/PublicReport/StudentByLevelsStatistics.vue";
 import VisitorsPublicStatistics from '@/views/Report/statistics/PublicReport/Visitors'
import SummerSchoolReportAndStatistic from '@/views/Report/statistics/SummerSchoolReportAndStatistic'
import FeedingReport from "@/views/Report/PublicReport/FeedingReport.vue"
import FeedingReportPeriod from "@/views/Report/PublicReport/FeedingReportPeriod.vue"





import UsersReport from '@/views/Report/PublicReport/UsersReport';

import SchoolReport from '@/views/Report/PublicReport/SchoolReport';
import SummerCenterNumberStudentAndEmployees from '@/views/Report/PublicReport/SummerCenterNumberStudentAndEmployees';
import SummerCenterStudentDataReport from '@/views/Report/PublicReport/SummerCenterStudentDataReport';
import SummerCenterEmployeesDataReport from '@/views/Report/PublicReport/SummerCenterEmployeesDataReport';

import FinalReport from "@/views/Summer/summerSchool/Reports/FinalReport"
import StudentsPerLevel from "@/views/Summer/summerSchool/Reports/StudentsPerLevel"
import PercentageStudentsPerGovernorate from "@/views/Summer/summerSchool/Reports/PercentageStudentsPerGovernorate"
import StatisticsofSchools from "@/views/Summer/summerSchool/Reports/StatisticsofSchools"
import StudentsDropoutBySchooltype from "@/views/Summer/summerSchool/Reports/StudentsDropoutBySchooltype"
import StudentsDropoutPerLevel from "@/views/Summer/summerSchool/Reports/StudentsDropoutPerLevel"
import OpenSchoolActivities from "@/views/Summer/summerSchool/Reports/OpenSchoolActivities"
import ClosedSchoolActivities from "@/views/Summer/summerSchool/Reports/ClosedSchoolActivities"
import TotalSchoolsActivities from "@/views/Summer/summerSchool/Reports/TotalSchoolsActivities"
import ClosedSchoolStatistics from "@/views/Summer/summerSchool/Reports/ClosedSchoolStatistics"
import ClosedSchoolStudentsStatistics from "@/views/Summer/summerSchool/Reports/ClosedSchoolStudentsStatistics"
import ClosedDropoutStudentsStatistics from "@/views/Summer/summerSchool/Reports/ClosedDropoutStudentsStatistics"
import OpenSchoolStatistics from "@/views/Summer/summerSchool/Reports/OpenSchoolStatistics"
import OpenSchoolStudentsStatistics from "@/views/Summer/summerSchool/Reports/OpenSchoolStudentsStatistics"
import OpenDropoutStudentsStatistics from "@/views/Summer/summerSchool/Reports/OpenDropoutStudentsStatistics"
import VisitsStatistics from "@/views/Summer/summerSchool/Reports/VisitsStatistics"
// import samerReport from '@/views/samer/SchoolReport';


const hasPermission = (prem) => {
  store.dispatch("updatePermission", localStorage.getItem("userinfo"));
  return store.getters.checkpermission(prem);
};

function CheckSummerCenterSystem(to, from, next) {
  const summer_center = localStorage.getItem("summer_center");
  if (summer_center === "true") {
    next();
  } else {
    next({
      path: "/",
      query: { redirect: from.fullPath },
    });
  }
}
const requirePermission = (perm) => {
  return (to, from, next) => {
    if (
      hasPermission(perm) &&
      localStorage.getItem("summer_center") == "true"
    ) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
    if (store.getters.checkrole(role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
export default [
  {
    path: "summer-center-statistics-governorate",
    name: "summer-center-statistics-governorate",
    component: SummerCenterStatisticsGovernorate,
  },
  {
    path: "openSchoolsWorkersStudents",
    name: "openSchoolsWorkersStudents",
    component: OpenSchoolsWorkersStudents,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-center-statistics-governorate",
    name: "summer-center-statistics-governorate",
    component: SummerCenterStatisticsGovernorate,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "summer-all-detailed-summary-table",
    name: "summer-all-detailed-summary-table",
    component: SummerAllDetailedSummaryTable,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "overall-summary-for-number-of-scholls-table",
    name: "overall-summary-for-number-of-scholls-table",
    component: OverallSummaryForNumberOfSchollsTable,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "numberStudentsAccordingLevelsGovernorates",
    name: "numberStudentsAccordingLevelsGovernorates",
    component: NumberStudentsAccordingLevelsGovernorates,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "visitsCarriedOutDuringDayDirectorate",
    name: "visitsCarriedOutDuringDayDirectorate",
    component: VisitsCarriedOutDuringDayDirectorate,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  //for statistics doing by bassel
  //dir
 
  {
    path: "StudentByLevelsStatistics",
    name: "StudentByLevelsStatistics",
    component: StudentByLevelsStatistics,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "TotalNumberOfActivitiesCarriedOutStatistics",
    name: "TotalNumberOfActivitiesCarriedOutStatistics",
    component: TotalNumberOfActivitiesCarriedOutStatistics,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  
  {
    path: "VisitorsDirectoratesStatistics",
    name: "VisitorsDirectoratesStatistics",
    component: VisitorsDirectoratesStatistics,
    beforeEnter: requireRole(['sys_admin','dir_admin']),
  },
  //end dir
   //gov
 
  {
    path: "StudentByLevelsGovStatistics",
    name: "StudentByLevelsGovStatistics",
    component: StudentByLevelsGovStatistics,
    beforeEnter: requireRole(['sys_admin','gov_admin']),
  },
  
  {
    path: "VisitorsGovStatistics",
    name: "VisitorsGovStatistics",
    component: VisitorsGovStatistics,
    beforeEnter: requireRole(['sys_admin','gov_admin']),
  },
// gov end
// Public
   {
    path: "CoursesForEachTypePublicStatistics",
    name: "CoursesForEachTypePublicStatistics",
    component: CoursesForEachTypePublicStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin']),
  },
   {
    path: "student-employee-present-actual",
    name: "student-employee-present-actual",
    component: DataReportOfStudentAndStaffPresentAndActual,
    beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin','gov_financial','gen_financial']),
  },
  //  {
  //   path: "dialy-summer-operations-report",
  //   name: "dialy-summer-operations-report",
  //   component: DialySummerOperationsReport,
  //   beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin']),
  // },
  {
    path: "ActivitiesStatistics",
    name: "ActivitiesStatistics",
    component: ActivitiesStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin']),
  },
  
  {
    path: "StudentByLevelsPublicStatistics",
    name: "StudentByLevelsPublicStatistics",
    component: StudentByLevelsPublicStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin']),
  },
 
  {
    path: "VisitorsPublicStatistics",
    name: "VisitorsPublicStatistics",
    component: VisitorsPublicStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin']),
  },
// Public end
//for statistics doing by bassel end
  {
    path: "openCoursesClosedSchools",
    name: "openCoursesClosedSchools",
    component: OpenCoursesClosedSchools,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "totalSchoolsEmployeesStudents",
    name: "totalSchoolsEmployeesStudents",
    component: TotalSchoolsEmployeesStudents,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {  
    path: "totalOpenCenters",
    name: "totalOpenCenters",
    component: TotalOpenCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "totalClosedCenters",
    name: "totalClosedCenters",
    component: TotalClosedCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "totalNumberStudentsGovernoratesLevel",
    name: "totalNumberStudentsGovernoratesLevel",
    component: TotalNumberStudentsGovernoratesLevel,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summerCenterStatistics",
    name: "summerCenterStatistics",
    component: SummerCenterStatistics,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "variousActivitiesDuringSummerCentersPeriodCapital",
    name: "variousActivitiesDuringSummerCentersPeriodCapital",
    component: VariousActivitiesDuringSummerCentersPeriodCapital,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "statisticsSummerCentersCapitalsMunicipalityDistricts",
    name: "statisticsSummerCentersCapitalsMunicipalityDistricts",
    component: StatisticsSummerCentersCapitalsMunicipalityDistricts,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "closedCentersInGovernorates",
    name: "closedCentersInGovernorates",
    component: ClosedCentersInGovernorates,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "typicalCenters",
    name: "typicalCenters",
    component: TypicalCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "openCenters",
    name: "openCenters",
    component: OpenCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "workingStaff",
    name: "workingStaff",
    component: WorkingStaff,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "statisticsTypeMaleFemaleTeacher",
    name: "statisticsTypeMaleFemaleTeacher",
    component: StatisticsTypeMaleFemaleTeacher,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "numberStudentsAccordingLevels",
    name: "numberStudentsAccordingLevels",
    component: NumberStudentsAccordingLevels,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "visitsCarriedOutDuringDay",
    name: "visitsCarriedOutDuringDay",
    component: VisitsCarriedOutDuringDay,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "visits-carried-out",
    name: "visits_carried_out",
    component: VisitsCarriedOut,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "visitsCarriedOutDuringDayDirectorate",
    name: "visitsCarriedOutDuringDayDirectorate",
    component: VisitsCarriedOutDuringDayDirectorate,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "centersGovernorateAffiliatedDirectorates",
    name: "centersGovernorateAffiliatedDirectorates",
    component: CentersGovernorateAffiliatedDirectorates,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "centersTypeOpenClosedTypical",
    name: "centersTypeOpenClosedTypical",
    component: CentersTypeOpenClosedTypical,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "activitiesInGovernorate",
    name: "activitiesInGovernorate",
    component: VariousActivitiesDuringSummerCentersPeriodCapital,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "totalSummaryOpenPositionsMFGovernorate",
    name: "totalSummaryOpenPositionsMFGovernorate",
    component: TotalSummaryOpenPositionsMFGovernorate,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "totalSummaryClosedPositionsMFGovernorate",
    name: "totalSummaryClosedPositionsMFGovernorate",
    component: TotalSummaryClosedPositionsMFGovernorate,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "totalSummaryTypicalPositionsMFGovernorate",
    name: "totalSummaryTypicalPositionsMFGovernorate",
    component: TotalSummaryTypicalPositionsMFGovernorate,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "governorateWorkingStaff",
    name: "governorateWorkingStaff",
    component: GovernorateWorkingStaff,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "schoolStatisticsTypeSchoolMFFirstReport",
    name: "schoolStatisticsTypeSchoolMFFirstReport",
    component: SchoolStatisticsTypeSchoolMFFirstReport,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "summer-all-detailed-summary",
    name: "summer-all-detailed-summary",
    component: SummerAllDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "statistics-schools-and-student-by-type",
    name: "statistics-schools-and-student-by-type",
    component: StatisticsSchoolsAndStudentByType,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "summer-total-activities-carried-out",
    name: "summer-total-activities-carried-out",
    component: SummerTotalActivitiesCarriedOut,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "schoolStatisticsTypeSchoolMFSecondReport",
    name: "schoolStatisticsTypeSchoolMFSecondReport",
    component: SchoolStatisticsTypeSchoolMFSecondReport,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "numberStudentsAccordingLevelsGovernorates",
    name: "numberStudentsAccordingLevelsGovernorates",
    component: NumberStudentsAccordingLevelsGovernorates,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  // {
  //   path: "numberStudentsAccordingLevelsGovernorates",
  //   name: "numberStudentsAccordingLevelsGovernorates",
  //   component: NumberStudentsAccordingLevelsGovernorates,
  //   beforeEnter: requireRole(['sys_admin','gov_admin']),
  // },
  {
    path: "reportVisitsCarriedDuringDayGovernorates",
    name: "reportVisitsCarriedDuringDayGovernorates",
    component: ReportVisitsCarriedDuringDayGovernorates,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "courses-for-each-type",
    name: "courses-for-each-type",
    component: CoursesForEachType,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },

  {
    path: "statistics-by-schools-type-student-type",
    name: "statistics-by-schools-type-student-type",
    component: StatisticsBySchoolsTypeStudentType,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "activities-directorates",
    name: "activities-directorates",
    component: VariousActivitiesDuringSummerCentersPeriodCapital,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "overall-summary-for-opened-courses",
    name: "overall-summary-for-opened-courses",
    component: OverallSummaryForOpenedCourses,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "overall-summary-for-closed-courses",
    name: "overall-summary-for-closed-courses",
    component: OverallSummaryForClosedCourses,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "overall-summary-for-typical-courses",
    name: "overall-summary-for-typical-courses",
    component: OverallSummaryForTypicalCourses,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "student-by-levels",
    name: "student-by-levels",
    component: StudentByLevels,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "summer-center-statistics",
    name: "summer-center-statistics",
    component: SummerCenterStatisticsDirectorates,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "school-report-dir",
    name: "school-report-dir",
    component: SchoolReport,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "school-report-gov",
    name: "school-report-gov",
    component: SchoolReport,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "summer-school-detailed-summary",
    name: "summer-school-detailed-summary",
    component: SummerSchoolDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "workers-summer",
    name: "workers-summer",
    component: WorkersSummer,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  // {
  //   path: "total-number-of-visitors-carried-out",
  //   name: "total-number-of-visitors-carried-out",
  //   component: TotalNumberOfVisitorsCarriedOut,
  //   beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  // },
  {
    path: "summer-courses-typical",
    name: "summer-courses-typical",
    component: TotalTypicalCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "students-summer",
    name: "students-summer",
    component: StudentsSummer,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "talented-students",
    name: "talented_students",
    component: TalentedStudents,
  },
  {
    path: "dropout-students",
    name: "dropout_students",
    component: DropoutStudents,
  },
  {
    path: "summer-student-detailed-summary",
    name: "summer-student-detailed-summary",
    component: SummerStudentDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-worker-detailed-summary",
    name: "summer-worker-detailed-summary",
    component: SummerWorkerDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-activities",
    name: "summer-activities",
    component: SummerActivities,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-curricula",
    name: "summer-curricula",
    component: SummerCurricula,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-work-progress",
    name: "summer-work-progress",
    component: SummerWorkProgress,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "governorate-statistic-by-school-type",
    name: "governorate-statistic-by-school-type",
    component: GovernorateStatisticBySchoolType,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "users-report",
    name: "users-report",
    component: UsersReport,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "users-report-dir",
    name: "users-report-dir",
    component: UsersReport,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "users-report-gov",
    name: "users-report-gov",
    component: UsersReport,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "school-report",
    name: "school-report",
    component: SchoolReport,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  
  {
    path: "overall-summary-for-number-of-schools",
    name: "overall-summary-for-number-of-schools",
    component: OverallSummaryForNumberOfSchools,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "summer-statistics-by-school-type",
    name: "summer-statistics-by-school-type",
    component: SummerStatisticsBySchoolType,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "workflow-in-summer",
    name: "workflow-in-summer",
    component: WorkflowInSummer,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "total-number-of-activities-carried-out",
    name: "total-number-of-activities-carried-out",
    component: TotalNumberOfActivitiesCarriedOut,
    beforeEnter: requireRole(["sys_admin", "gov_admin"]),
  },
  {
    path: "total-number-of-activities-carried-out-during-day",
    name: "total-number-of-activities-carried-out-during-day",
    component: TotalNumberOfActivitiesCarriedOutDuringDay,
    beforeEnter: requireRole(["sys_admin", "dir_admin"]),
  },
  {
    path: "summer-school-report-statistic",
    name: "summer-school-report-statistic",
    component: SummerSchoolReportAndStatistic,
    beforeEnter: requireRole(["sys_admin", "gen_admin","dir_admin","gov_admin"]),
  },
  {
    path: "summer-center-number-student-employees",
    name: "summer-center-number-student-employees",
    component: SummerCenterNumberStudentAndEmployees,
    beforeEnter: requireRole(["sys_admin", "gen_admin","dir_admin","gov_admin"]),
  },
  {
    path: "summer-center-student-data-report",
    name: "summer-center-student-data-report",
    component: SummerCenterStudentDataReport,
    beforeEnter: requireRole(["sys_admin", "gen_admin","dir_admin","gov_admin"]),
  },
  {
    path: "summer-center-employees-data-report",
    name: "summer-center-employees-data-report",
    component: SummerCenterEmployeesDataReport,
    beforeEnter: requireRole(["sys_admin", "gen_admin","dir_admin","gov_admin"]),
  },
  {
    path: "feeding-report",
    name: "feeding_report",
    component: FeedingReport,
    beforeEnter: requireRole(["gen_admin", "gov_admin","sys_admin",'gov_financial','gen_financial']),
  },
  {
    path: "feeding-report-periods",
    name: "feeding_report_periods",
    component: FeedingReportPeriod,
    beforeEnter: requireRole(["gen_admin", "gov_admin","sys_admin",'gov_financial','gen_financial']),
  },
  {
    path: "final-report",
    name: "open_summer_school_final_report",
    component: FinalReport,
    beforeEnter: requireRole(["gen_admin","sys_admin"]),
  },
  {
    path: "students-per-level",
    name: "students_per_level",
    component: StudentsPerLevel,
    beforeEnter: requireRole(["gen_admin","sys_admin"]),
  },
  {
    path: "relative-statistics-students-governorate",
    name: "relative_statistics_students_governorate",
    component: PercentageStudentsPerGovernorate,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "statistics-of-summer-schools",
    name: "statistics_of_summer_schools",
    component: StatisticsofSchools,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "dropout-students-by-school-type",
    name: "dropout_students_by_school_type",
    component: StudentsDropoutBySchooltype,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "dropout-students-per-level",
    name: "dropout_students_per_level",
    component: StudentsDropoutPerLevel,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "open-school-activities",
    name: "open_school_activities",
    component: OpenSchoolActivities,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "closed-school-activities",
    name: "closed_school_activities",
    component: ClosedSchoolActivities,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "total-school-activities",
    name: "total_school_activities",
    component: TotalSchoolsActivities,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "closed-school-statistics",
    name: "closed_school_statistics",
    component: ClosedSchoolStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "closed-students-statistics",
    name: "closed_students_statistics",
    component: ClosedSchoolStudentsStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "closed-dropout-students-statistics",
    name: "closed_dropout_students_statistics",
    component: ClosedDropoutStudentsStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "open-school-statistics",
    name: "open_school_statistics",
    component: OpenSchoolStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "open-school-students-statistics",
    name: "open_school_students_statistics",
    component: OpenSchoolStudentsStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "open-dropout-students-statistics",
    name: "open_dropout_students_statistics",
    component: OpenDropoutStudentsStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
  {
    path: "visits-statistics",
    name: "visit_statistics",
    component: VisitsStatistics,
    beforeEnter: requireRole(["gen_admin", "sys_admin"]),
  },
];
