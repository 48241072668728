<template>
<template v-if="!isexit">
    
  <v-expansion-panels class="mb-2">
    <v-expansion-panel >
      <v-expansion-panel-title class="px-4">
        <v-icon icon="mdi-sort-calendar-ascending"></v-icon>
        <span class="mx-2">{{ $t("globals.select-others-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <VRow>
          <VCol cols="6" md="3" class="mt-1" sm="4" v-if="visiGovernorat">
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_governorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorate"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact" 
                clearable
                hide-details
                @update:modelValue="page=1,fk_directorate = undefined,fetchData()"
              ></v-autocomplete>
            </v-card-item>
          </VCol>
          <VCol cols="6" md="3" class="mt-1" sm="4" v-if="visiDirectorate">
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_directorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGov(fk_governorate)"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                clearable
                hide-details
                @update:model-value="page=1,fetchData()"

              ></v-autocomplete>
            </v-card-item>
          </VCol>
          <VCol cols="6" md="3" class="mt-1" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="lessonRecord.fk_week"
              density="compact"
              item-title="week_name"
              item-value="id"
              prepend-inner-icon="mdi-calendar-week"
              clearable
              :items="weeks"
              hide-details
              :label="$t('summer.calendar.week-name')"
              @update:model-value="page=1,getDateList(), resetForm(1)"
              :rules="rules"
            />
          </VCol>
          <VCol cols="6" md="3" class="mt-1" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="lessonRecord.date"
              density="compact"
              item-title="week_name"
              item-value="id"
              prepend-inner-icon="mdi-calendar-today"
              clearable
              hide-details
              :items="listWeeks"
              :label="$t('summer.calendar.hijri-date')"
              :rules="rules"
              @update:model-value="page=1,resetForm(0), fetchData()"
            />
          </VCol>
          <VCol cols="6" md="3" class="mt-1" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="summer_type_id"
              density="compact"
              item-title="type"
              item-value="id"
              :clearable="false"
              prepend-inner-icon="mdi-calendar-week"
              :items="typeList"
              hide-details
              :label="$t('summer.summer_type')"
              @update:model-value="page=1,fetchData()"

                
            />
          </VCol>
          <VCol  cols="6" md="3" class="mt-1" sm="4">
            <VAutocomplete
              :items="period_choices"
              v-model="period"
              item-title="val"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('globals.period')"
              density="compact"
              prepend-inner-icon="mdi-timetable"
              @update:model-value="page=1,fetchData()"

            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" class="mt-2" sm="4">
            <VBtn
              class="bg-indigo"
              type="submit"
              @click="changeStaute"
              size="small"
            >
              <span class="text-white">
                {{ $t("permission.view_report") }}
              </span>
              <VIcon icon="mdi-presentation" color="white" end></VIcon>
            </VBtn>
          </VCol>
        </VRow>
       
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
 <!-- <v-progress-linear
          color="indigo"
          v-if="loading"
          indeterminate
          height="2px"
          :reverse="$i18n.locale == 'ar'"
        >
        </v-progress-linear> -->
  <v-card class="mb-3 " >
    <v-card-text>
      <div class="d-flex justify-space-between mb-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div class="w-100 px-2">
            <v-text-field
              v-model="txt_search"
              color="indigo"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
              @input="fetchData(page=1)"
            >
            </v-text-field>
          </div>
          
        </div>
        <v-btn-group density="compact"  divided >
          <!-- <v-btn size="small" @click="asyncStatistics" :loading="syncProgress">
            <span>{{$t('globals.async-statistics')}}</span>
            <v-icon size="large" color="blue-darken-2" end>mdi-file-sync-outline</v-icon>
          </v-btn>   -->
          <v-btn  size="small"  @click="exportToExcel">
              <span>{{$t('export.export')}} </span>
              <v-icon size="large" color="green-darken-2" end>mdi-file-excel</v-icon>
          </v-btn>
        </v-btn-group>
      </div>
      <v-card :loading="loading"  :disabled="loading || syncProgress">
        <v-data-table-server
          :headers="selectedHeaders"
          :items="dataTable"
          :search="txt_search"
          fixed-header
          :height="dataTable?.length>10?400:null"
          density="compact"
          class="elevation-1"
          
        >
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="length"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [ {{ totalItems }} ]
                  {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server> 
      </v-card>
     </v-card-text>
  </v-card>

  
</template>
<template v-else-if="isexit">

  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SummerCouresStatistics") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            @click="printTable()"
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn density="compact" class="mx-1" @click="isexit = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.SchoolReport") }}

              {{ title }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4></h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-table
        density="compact"
        style="border: 0.2px solid #649add !important; overflow: auto"
        class="report-table"
      >
        <thead>
          <tr>
            <th rowspan="2" v-for="(data, index) in header" :key="index">
              {{ data }}
            </th>
            <th
              class="text-center"
              v-for="(data, index) in schools_summer.header"
              colspan="2"
              :key="index"
            >
              {{ data }}
            </th>
            <th
              class="text-center"
              :colspan="schools_summer.headerActivaty.length"
            >
              {{ $t("permission.activity") }}
            </th>
            <th rowspan="2">{{ $t("statistics.martyrs-sons") }}</th>
            <th rowspan="2">{{ $t("statistics.martyrs-daughters") }}</th>
            <th rowspan="2">{{ $t("globals.total_schools") }}</th>
            <th rowspan="2">{{ $t("globals.total_workers_male_female") }}</th>
            <th rowspan="2">{{ $t("globals.totla_male_students") }}</th>
            <th rowspan="2">{{ $t("globals.total_female_students") }}</th>
            <th rowspan="2">{{ $t("globals.total_students") }}</th>
            <th rowspan="2">{{ $t("statistics.official-visits") }}</th>
            <th rowspan="2">{{ $t("statistics.supervision-visits") }}</th>
            <th rowspan="2">{{ $t("statistics.community-visits") }}</th>
            <th rowspan="2">{{ $t("globals.activaty_group_count") }}</th>
            <th rowspan="2">{{ $t("globals.group_activaty_count") }}</th>
            <th rowspan="2">{{ $t("globals.committee_count") }}</th>
            <th rowspan="2">{{ $t("globals.recive_approch_count") }}</th>
          </tr>

          <tr>
            <template
              v-for="(data, index) in schools_summer.header.length"
              :key="index"
            >
              <th>عدد الطلاب</th>
              <th>عدد الطالبات</th>
            </template>
            <th
              v-for="(data, index) in schools_summer.headerActivaty"
              :key="index"
            >
              {{ data }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style="border: 0.5px solid #649add;" v-for="(data, index) in schools_summer.data" :key="index">
            <td class="text-no-wrap">
              {{ data.summer_name_ar }}
            </td>
            <td>
              {{
                this.$i18n.locale == "ar"
                  ? data.governorate_name_ar
                  : data.governorate_name_en
              }}
            </td>
            <td>
              {{
                this.$i18n.locale == "ar"
                  ? data.directorate_name_ar
                  : data.directorate_name_en
              }}
            </td>
            <td>
              {{ data.place_type }}
            </td>
            <td>
              {{ data.school_type }}
            </td>
            <td>
              {{ data.students_type }}
            </td>
            <td>{{ data.male_summer_count }}</td>
            <td>{{ data.female_summer_count }}</td>
            <td>
              {{ data.male_employees_count }}
            </td>
            <td>
              {{ data.female_employees_count }}
            </td>

            <template v-for="(l, i) in data.data_Level_statistics" :key="i">
              <td class="text-center">
                {{ l.male_students_count }}
              </td>
              <td class="text-center">
                {{ l.female_students_count }}
              </td>
            </template>
            <template v-for="(item, i) in data.data_activity" :key="i">
              <td class="text-center">
                {{ item }}
              </td>
            </template>
            <td>
              {{ data.martyrs_sons }}
            </td>
            <td>
              {{ data.martyrs_daughters }}
            </td>

            <td>
              {{ data.totals_school }}
            </td>
            <td>
              {{ data.totals_employee }}
            </td>
            <td>
              {{ data.totals_male_student }}
            </td>
            <td>
              {{ data.totals_female_student }}
            </td>
            <td>
              {{ data.totals_student }}
            </td>

            <td>
              {{ data.official_visits }}
            </td>
            <td>
              {{ data.supervision_visits }}
            </td>
            <td>
              {{ data.community_visits }}
            </td>
            <td>
              {{ data.activaty_group_count }}
            </td>
            <td>
              {{ data.group_activaty_count }}
            </td>
            <td>
              {{ data.committee_count }}
            </td>
            <td>
              {{ data.recive_approch_count }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-item>
  </v-card>
    
  
</template>


</template>
<script>
;
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { registerTheme } from "echarts";
 
export default {
  components: {
    ReportHeader,
     
  },
  data() {
    return {
      loading:null,
      syncProgress:false,
      period:null,
      schools_summer: [],
      fk_governorate: null,
      fk_directorate: null,
      visiGovernorat: true,
      visiDirectorate: true,
      isexit: false,
      currnet_date_id: null,
      currnet_week_id: null,
      summer_type_id: 0,
      lessonRecord: {},
      listWeeks: [],
      period_choices:[
        {id:1,val:this.$t('globals.one-period')},
        {id:2,val:this.$t('globals.two-periods')},
      ],
      typeList: [
        { id: 0, type: this.$t("globals.all") },
        { id: 1, type: this.$t("reportpublic.Open") },
        // { id: 2, type: this.$t("reportpublic.Close") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],

      header: [
        this.$t("globals.summer-centre"),
        this.$t("globals.governorate"),
        this.$t("globals.directorate"),
        this.$t("summer.place-type"),
        this.$t("summer.summer_type"),
        this.$t("school.students_type"),
        this.$t("globals.male_school"),
        this.$t("globals.fmale_school"),
        this.$t("statistics.male-employee"),
        this.$t("statistics.female-employee"),
      ],
      length: null,
      page: 1,
      perPage: 10,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      pagination: {},
      totalItems:null,
      txt_search:null,
      headers:[],
      basic_headers: [
        { title: this.$t("globals.summer-centre"), key: "summer_name_ar" },
        { title: this.$t("globals.governorate"), key: "governorate_name_ar" },
        { title: this.$t("globals.directorate"), key: "directorate_name_ar" },
        { title: this.$t("summer.place-type"), key: "place_type" },
        { title: this.$t("summer.summer_type"), key: "school_type" },
        { title: this.$t("school.students_type"), key: "students_type" },
        { title: this.$t("globals.male_school"), key: "male_summer_count" },
        { title: this.$t("globals.fmale_school"), key: "female_summer_count" },

        {
          title: this.$t("statistics.male-employee"),
          key: "male_employees_count",
        },
        {
          title: this.$t("statistics.female-employee"),
          key: "female_employees_count",
        },
      ],
      selectedHead: [],
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
     
     
    };
  },

  computed: {
    dateToday(){
      const today = new Date()
      const options ={year:'numeric',month:'2-digit',day:'2-digit'}
      const dateString = today.toLocaleDateString('en-US',options).replace(/\//g,'-')
      return dateString
    },
    dataTable(){
      var data=[]
      if(this.schools_summer.data){
        this.schools_summer.data.forEach(item=>{
         const data_level= item['data_Level_statistics']
         const data_activity= item['data_activity']
         if(data_level.length>0){
          this.schools_summer.header.forEach((el,index)=>{
            const level_name_male=el+"  ذكور"
            const level_name_femal=el+"  اناث"
            item[level_name_male]=data_level[index]?.male_students_count
            item[level_name_femal]=data_level[index]?.female_students_count
          })
         }
         if(data_level.length>0){
          this.schools_summer.headerActivaty.forEach((el,index)=>{
            item[el]=data_activity[index]
          })
         }
         data.push(item)
        })
      }
      return data;
    },
    ...mapState({
      weeks: (state) => state.summer.weeks,
      governorate: (state) => state.school.governorates,
      role: (state) => state.role,
    }),

    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
    selectedHeaders() {
      return this.headers.filter((h) => this.selectedHead.includes(h.key));

    },
  },

  async mounted() {},
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.fetchWeek();
      await this.getDateList()
      this.headers=Object.assign([],this.basic_headers)
      await this.getGovernorates();
      await this.getDirectorate();
      await this.fetchData();
      // var role = localStorage.getItem("role");
      if (this.role == 2){
        this.visiGovernorat=false;
        this.fk_governorate = parseInt(localStorage.getItem("governorate"));
      }else if (this.role == 3){
        this.visiGovernorat=false;
        this.visiDirectorate=false;
        this.fk_governorate = parseInt(localStorage.getItem("governorate"));
        this.fk_directorate = parseInt(localStorage.getItem("directorate"));
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      console.log(error);
      this.$store.commit("updateLoadingValue", false);
    }
    
  },
  methods: {
    changeStaute() {
      if (this.lessonRecord.date) {
        this.isexit = true;
      }
    },
    ...mapActions({
      getWeeks: "summer/getWeeks",

      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    exportToExcel() {
      const headers=this.selectedHeaders.map(item=>{
        if(item.key=='summer_name_ar'){
          return{ header:item.title,key:item.key }
        }
        return{ header:item.title,key:item.key }
      })
      this.$ExportToExcel(headers,this.dataTable,this.$t('summer.school-statistics'))
      
    },
    getDirectorateByGov(gov) {
      if (this.role == 3) {
        var l = this.getDirectorateByGovernorates(gov);

        return l.filter(
          (item) => item.id == localStorage.getItem("directorate")
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },
    async getDateList() {
      if (this.lessonRecord.fk_week) {
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            params: {
              week_id: this.lessonRecord.fk_week,
            },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {
              
          });
      } else {
        this.resetForm();
      }
    },
    async fetchWeek() {
      await  this.axios
        .get(`${this.base_url}statistics/get-current-date`)
        .then((response) => {
          this.lessonRecord.fk_week = response.data.week_id;
          this.lessonRecord.date = response.data.current_date;
          this.currnet_week_id = response.data.week_id;
          this.currnet_date_id = response.data.current_date;
        })
        .catch((error) => {
            
        });
    },
    async fetchData(page=1) {
      this.loading=true
      var date_day = "";
      var gov_id = "";
      var dir_id = "";
      if (this.lessonRecord.date) {
        date_day = this.lessonRecord.date;
        if (this.fk_governorate) {
          gov_id = this.fk_governorate;
        }
        if (this.fk_directorate) {
          dir_id = this.fk_directorate;
        }

        await this.axios
          .get(`${this.base_url}public-reports/summer-statistics-report/`, {
            params: {
              day: date_day,
              gov_id: gov_id,
              dir_id: dir_id,
              type_id:this.summer_type_id,
              page_size:this.perPage,
              page:this.page,
              period:this.period,
              txt_search:this.txt_search

            },
          })
          .then((response) => {
            this.loading=false
            this.schools_summer = response.data.results;
            this.totalItems=response.data.pagination.count
            this.itemsPerPage[4].value=response.data.pagination.count
            this.length=response.data.pagination.num_pages
            this.headers=Object.assign([],this.basic_headers)
            this.schools_summer.header.map((e) => {
              this.headers.push({
                title: e + "  ذكور",
                key: e + "  ذكور",
              });
              this.headers.push({
                title: e + "   اناث",
                key: e + "  اناث",
              });
            });
            this.schools_summer.headerActivaty.map((e) => {
              this.headers.push({
                title: e,
                key: e,
              });
            });
            this.headers.push(
              {
                title: this.$t("statistics.martyrs-sons"),
                key: "martyrs_sons",
              },
              {
                title: this.$t("statistics.martyrs-daughters"),
                key: "martyrs_daughters",
              },

              { title: this.$t("globals.total_schools"), key: "totals_school" },
              {
                title: this.$t("globals.total_workers_male_female"),
                key: "totals_employee",
              },
              {
                title: this.$t("globals.totla_male_students"),
                key: "totals_male_student",
              },
              {
                title: this.$t("globals.total_female_students"),
                key: "totals_female_student",
              },
              {
                title: this.$t("globals.total_students"),
                key: "totals_student",
              },

              {
                title: this.$t("statistics.official-visits"),
                key: "official_visits",
              },
              {
                title: this.$t("statistics.supervision-visits"),
                key: "supervision_visits",
              },
              {
                title: this.$t("statistics.community-visits"),
                key: "community_visits",
              },

              {
                title: this.$t("globals.activaty_group_count"),
                key: "activaty_group_count",
              },
              {
                title: this.$t("globals.group_activaty_count"),
                key: "group_activaty_count",
              },
              {
                title: this.$t("globals.committee_count"),
                key: "committee_count",
              },
              {
                title: this.$t("globals.recive_approch_count"),
                key: "recive_approch_count",
              }
            );

            this.selectedHead = this.headers.map((e) => e.key);
          }).catch(error=>{
            this.loading=false
            this.schools_summer = [];
          })
      } else {
        this.loading=false
        this.schools_summer = [];
      }
    },
    async printTable() {
      window.print();
    },
    resetForm(resetAll) {
      if (resetAll) {
        this.lessonRecord.date = null;
        this.listWeeks = [];
        this.results = [];
      } else {
        this.results = [];
        this.isexit = false;
      }
    },

    async clearweek() {
      this.listWeeks = [];

      this.lessonRecord.date = this.currnet_date_id;
      this.lessonRecord.fk_week = this.currnet_week_id;
      this.summer_type_id = 0;
    },
    async asyncStatistics(){
      this.syncProgress=true
      await this.axios(`${this.base_url}/public/statistic-async/`,{
          params:{all_summers:1,}
        }).then((response) => {
          this.fetchData()
          this.syncProgress=false
        }).catch((error)=>{
          this.syncProgress=false
        })
      
    }
  },
  watch:{
    perPage() {
      this.page=1
      this.fetchData();
    },
    page() {
      this.fetchData(this.page);
    },
  }
};
</script>
<style scoped>

</style>
