<template>
  <v-card
    class="justify-center"
    v-if="
      CheckGetPermission('summer_center.view_studentsummer', [
        'sys_admin',
        
        'summer_admin',
        'user',
      ])
    "
  >
    <v-card-text class="mb-4">
      <v-row class="justify-center">
        <v-col cols="12">
          <!-- <v-card class="pa-2 ma-auto" > -->
          <v-form ref="form"  >
            <v-row class="mt-4">
              <v-col cols="12" md="5" sm="4">
                <VTextField
                  v-model="academic_id"
                  :label="$t('globals.student_id')"
                  persistent-hint
                  clearable
                  density="compact"
                  :rules="rules.title"
                  :placeholder="$t('summer.student.enter-academic-num')"
                  prepend-inner-icon="mdi-account"
                ></VTextField>
              </v-col>

              <v-col cols="4" md="1" class="mt-2 mb-4">
                <v-btn
                  @click="getStudenthDropout(), (clicked = true)"
                  :loading="DataLoading"
                  class="bg-indigo"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>

            <v-card v-if="exist" class="pa-2">
              <span>{{$t('school.student_name')}} : {{students.student_name}}</span>
              <v-row class="mt-1">
                <VCol
                  class="mt-1"
                  cols="6"
                  md="3"
                  sm="4"
                  v-if="role == 0 || role == 1"
                >
                  <VAutocomplete
                    :items="filterGovernorates(1)"
                    v-model="fk_governorate"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('globals.governorate')"
                    persistent-hint
                    density="compact"
                    hide-details="auto"
                    clearable
                    :no-data-text="$t('governorate.not-found')"
                    prepend-inner-icon="mdi-city"
                    @update:modelValue="
                      (page = 1),
                        getSummerSchools(),
                        (fk_directorate = null),
                        (students.fk_summer = null)
                    "
                  ></VAutocomplete>
                </VCol>
                <VCol
                  class="mt-1"
                  cols="6"
                  md="3"
                  sm="4"
                  v-if="role >= 0 && role <= 2"
                >
                  <VAutocomplete
                    :items="filterDirectorates(fk_governorate)"
                    v-model="fk_directorate"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('globals.directorate')"
                    persistent-hint
                    hide-details
                    density="compact"
                    clearable
                    :no-data-text="$t('directorate.not-found')"
                    prepend-inner-icon="mdi-map-outline"
                    @update:modelValue="
                      (page = 1), getSummerSchools(), (students.fk_summer = null)
                    "
                  ></VAutocomplete>
                </VCol>
                <VCol
                  class="mt-1"
                  cols="6"
                  md="3"
                  sm="4"
                  v-if="role >= 0 && role <= 3"
                >
                  <VAutocomplete
                    v-model="students.fk_summer"
                    item-value="id"
                    item-title="name_ar"
                    prepend-inner-icon="mdi-domain"
                    :items="summer_schools"
                    :label="$t('globals.summer-centre')"
                    persistent-hint
                    hide-details="auto"
                    :rules="role=>0&&role<=3?fk_summer_required:null"
                    density="compact"
                    :no-data-text="$t('summer.not-found')"
                    @update:modelValue="page = 1"
                    clearable
                  ></VAutocomplete>
                </VCol>
              </v-row>

              <v-row > 
                <v-col class="mt-1" cols="6" md="3" sm="4">
                  <v-select
                    v-model="students.fk_level"
                    density="compact"
                    :item-title="classesName"
                    item-value="id"
                    :no-data-text="$t('globals.not-found')"
                    :items="classes"
                    hide-details="auto"
                    @update:modelValue="students.fk_division = null"
                  >
                  </v-select>
                  
                </v-col>
                <v-col class="mt-1" cols="6" md="3" sm="4">
                  <v-select
                    v-model="students.fk_division"
                    density="compact"
                    item-title="name"
                    item-value="id"
                    :no-data-text="$t('globals.not-found')"
                    :items="class_divisions"
                    hide-details="auto"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-card-actions v-if="exist" class="mt-2">
                <v-btn
                  :loading="loading"
                  @click="saveStudentDropout"
                  class="bg-indigo"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.move") }}
                  </span>
                  <v-icon icon="mdi-file-move" color="white" end></v-icon>
                </v-btn>
                <v-btn
                  @click="cleanStudentDropoutForm"
                  class="mx-3 bg-ccc"
                  size="small"
                >
                  <span>
                    {{ $t("globals.clear") }}
                  </span>
                  <v-icon icon="mdi-broom" color="golden" end></v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <div></div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "CorrectionDropout",
  props: {
    id: String,
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.role == 2) {
        this.fk_governorate = localStorage.getItem("governorate");
      }
      if (this.role == 3) {
        this.fk_directorate = localStorage.getItem("directorate");
        await this.getSummerSchools();
      }
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getClasses();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getStudyMode();
      await this.getDropOutReason();
      await this.getGovernorates();
      await this.getDirectorate();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      loading: false,
      schedules_data: [],
      fk_governorate: null,
      fk_directorate: null,
      fk_summer: null,
      summer_schools: [],
      v$: useValidate(),
      showAlert: false,
      update: false,
      user: null,
      exist: false,
      DataLoading: false,
      clicked: false,
      student: [],
      valid: false,
      is_moveable: false,
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      user: null,
      academic_id: null,
      class_Dropouts: [],
      Dropouts: [],
      fk_Dropout: undefined,
      studentDropout: {
        is_checked: false,
        fk_student: undefined,
        note: "",
      },
      rules: {
        title: [(value) => !!value || this.$t("globals.required_field")],
      },
      fk_summer_required:[
        (value) => !!value || this.$t("globals.required_field")
      ],
      portability: [
        { name: "قابل للنقل", is_moveable: true },
        { name: "غير قابل للنقل", is_moveable: false },
      ],
      students: {
        fk_level: undefined,
        fk_division: null,
        fk_student: undefined,
      },
      max_entry: [(value) => value == null || value.length < 250 || ""],
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      study_status: (state) => state.summer_globals.study_mode,
      place_type: (state) => state.summer_globals.drop_out_reason,
      role: (state) => state.role,
    }),
    ...mapGetters({
      getDivisionName: "summer/getDivisionName",
      getClassDivisions: "summer/getClassDivisions",
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    class_divisions() {
      if (this.students.fk_level) {
        return this.getClassDivisions(this.students.fk_level);
      }
    },
    classesName() {
      return this.$i18n.locale == "ar" ? "level_with_phase_name" : "name_en";
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  validations() {
    return {
      title: {
        required: helpers.withMessage(
          this.$t("globals.required_field"),
          required
        ),
      },
    };
  },
  methods: {
    ...mapActions({
      getDivisions: "summer/getDivisions",
      getClasses: "summer/getLevels",
      getClassAssignHall: "summer/getClassAssignHall",
      getStudyMode: "summer_globals/getStudyMode",
      getDropOutReason: "summer_globals/getDropOutReason",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.fk_directorate,
            governorate: this.fk_governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    async getStudenthDropout() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.DataLoading = true;
        await this.axios
          .get(`${this.base_url}api/summer/reregistration-student`, {
            params: {
              academic_id: this.academic_id,
            },
          })
          .then((response) => {
            this.exist = true;
            this.students = response.data.results;
            this.DataLoading = false;
          })
          .catch((error) => {
            this.DataLoading = false;
            this.exist = false;
            this.$emit("errorAlert", this.$t("alert.failure.exist"));
            if (error.message == "Request failed with status code 452") {
              this.$emit(
                "infoAlert",
                this.$t("alert.failure.exist-but-not-movable")
              );
            }
          });
      }
    },
    async saveStudentDropout() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.axios
          .post(
            `${this.base_url}/api/summer/reregistration-student`,
            this.students
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_saved"));
            this.loading = false;
            this.academic_id = null;
            this.exist = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.insert"));
            this.loading = false;
            try {
              if (
                error.response.data.study_mode[0].includes(
                  "This field may not be null."
                )
              ) {
                this.alert.message = this.$t("alert.failure.insert");
              } else {
                this.alert.message = this.$t("alert.failure.insert");
              }
            } catch (e) {
              this.alert.message = this.$t("alert.failure.insert");
            }
          });
      }
    },
    cleanStudentDropoutForm() {
      this.v$.$reset();
      this.exist = false;
      this.DataLoading = false;
      this.clicked = false;
      this.students = [];
      this.class_Dropouts = [];
      this.fk_level = [];
    },
  },
};
</script>
