<template>
  <v-card v-if="!exist && role <= 3">
    <v-card-text>
      <h3 class="text-grey-darken-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <v-col
          cols="6"
          md="3"
          sm="4"
          class="mb-1"
          v-if="role >= 0 && role <= 1"
        >
          <VAutocomplete
            :items="filterGovernorates(1)"
            v-model="fk_governorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.governorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('governorate.not-found')"
            prepend-inner-icon="mdi-city"
            @update:modelValue="fk_directorate=null"
          >
          </VAutocomplete>
        </v-col>
        <VCol cols="6" md="3" sm="4" v-if="role >= 0 && role <= 2">
          <VAutocomplete
            :items="filterDirectorates(fk_governorate)"         
            v-model="fk_directorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.directorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('directorate.not-found')"
            prepend-inner-icon="mdi-map-outline"
          ></VAutocomplete>
        </VCol>
        <VCol cols="6" md="3" sm="4" >
          <DatetimePicker
            v-model="date"
            :calendar="'gregorg'"
            :placeholder="$t('globals.year-month-day')"
            :label="$t('globals.date')"
          ></DatetimePicker>
        </VCol>
        <v-col cols="6" md="2" sm="4" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="getData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat v-if="exist" id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center pt-4">
          <h1 class="text-h6">
           {{reportTitle}}
          </h1>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="text-end mt-2" id="tableHead">
      <v-btn-group>
        <v-btn density="compact" class="pa-0" @click="exportToExcel">
          <span>{{ $t("export.export") }} </span>
          <v-icon size="large" color="green-darken-2" end
            >mdi-file-excel</v-icon
          >
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("export.excel") }}</small>
          </v-tooltip>
        </v-btn>
        <v-btn
          v-bind="props"
          density="compact"
          style="min-width: 25px"
          @click="printTable"
        >
          <v-icon color="icon-color">mdi-printer</v-icon>
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("globals.print-file") }}</small>
          </v-tooltip>
        </v-btn>
        <v-btn
          density="compact"
          @click="exist = false"
        >
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </v-btn-group>
    </v-card-title>
    <v-card-text class="mx-0 px-0 mt-4">
      <div id="myTable">
        <v-card
          class="pa-3 border rounded-lg"
          flat
          elevation="0"
          v-if="items?.data?.length < 1"
        >
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <table
          class="report-t text-center"
          ref="table"
          v-if="items?.data?.length > 0"
        >
          <thead>
            <tr>
              <th v-for="head in headers" :key="head">
                {{head.header}}
              </th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(user,index) in items.data" :key="index">
                <td >{{ user?.username }}</td>
                <td >
                  {{ user?.fullname?? "---"}}
                </td>
                <td >{{ user?.role_name }}</td>
                <td >{{ user?.governorate == "" ? "---" : user?.governorate }}</td>
                <td >{{ user?.directorate == "" ? "---" : user?.directorate }}</td>
                <td >{{ user?.schools_count }}</td>
                <td >{{ user?.employees_count }}</td>
              </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="5">{{ $t("globals.grand-total") }}</th>
              <th>{{ items?.total?.schools_count ?? 0 }}</th>
              <th>{{ items?.total?.employees_count ?? 0 }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getDirectorate();

      if (this.role == 2) {
        this.fk_governorate = parseInt(localStorage.getItem("governorate"));
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      fk_governorate: null,
      fk_directorate: null,
      student_type: null,
      date:null,
      exist: false,
      loading: false,
      headers:[
        {header:this.$t("user.username"),key:"username"},
        {header:this.$t("user.full-name"),key:"fullname"},
        {header:this.$t("user.role"),key:"role_name"},
        {header:this.$t("globals.governorate"),key:"governorate"},
        {header:this.$t("globals.directorate"),key:"directorate"},
        {header:this.$t("globals.schools-count"),key:"schools_count"},
        {header:this.$t("globals.employees-count"),key:"employees_count"}
      ]
    };
  },
  computed: {
    ...mapState({
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      role: (state) => state.role,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    reportTitle(){
      const title = " تقرير بمراجعة بيانات المدارس والموظفين "
      var str = ''
      if(this.fk_governorate){
        str = `${this.$t("globals.for-governorate")} ( ${this.getGovernorateName(this.fk_governorate)}  )`
      }else{
        str = `( ${this.$t("globals.for-all-governorates") })`
      }
      if(this.fk_directorate){
        str+=`${this.$t("globals.for-directorate")} ( ${this.getDirectorateName(this.fk_directorate)}  )`
      }else{
        str+=`( ${this.$t("globals.for-all-directorates") })`
      }
      return `${title} ${str}`

    },
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getStudentType: "summer_globals/getStudentType",
    }),
    async exportToExcel() {
      const data = [...this.items?.data,this.items?.total]
      this.$ExportToExcel(this.headers,data,this.reportTitle);
    },
    getDirectorateName(dir_id) {
      return this.directorates?.find((dir) => dir.id == dir_id)?.name_ar;
    },
    getGovernorateName(gov_id) {
      return this.governorates?.find((gov) => gov.id == gov_id)?.name_ar;
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/reviewers-report`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            governorate: this.fk_governorate,
            directorate: this.fk_directorate,
            date:this.date
          },
        })
        .then((response) => {
          this.loading = false;
          this.exist = true;
          this.items = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.exist = false;
        });
    },
  },
};
</script>

