<template>
  <v-card class="pb-3 px-2 mb-2">
    <v-card-title>{{ $t("globals.select-criteria") }}</v-card-title>
    <v-row class="py-2">
      <v-col cols="12" md="3">
        <VAutocomplete
          :label="$t('log-entries.action')"
          item-title="val"
          item-value="id"
          :items="[
            { id: 1, val: $t('permission.add') },
            { id: 2, val: $t('permission.change') },
            { id: 3, val: $t('permission.delete') },
          ]"
          type="data"
          v-model="action_id"
          persistent-hint
          density="compact"
          clearable
          hide-details="auto"
          @update:modelValue="getData()"
        ></VAutocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <VAutocomplete
          :label="$t('globals.content-type')"
          item-title="name"
          item-value="id"
          :items="contentTypes"
          v-model="contentTypeId"
          persistent-hint
          density="compact"
          clearable
          hide-details="auto"
          @update:modelValue="getData()"
        ></VAutocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <DatetimePicker
          v-model="date"
          :calendar="'hijri'"
          :placeholder="$t('globals.year-month-day')"
          :label="$t('globals.date')"
          @update:modelValue="date?.length > 9 ? getData() : null"
        ></DatetimePicker>
      </v-col>
    </v-row>
  </v-card>
  <v-card :loading="action_id || content_type || date?loading:null" :disabled="action_id || content_type || date?loading:null">
    <DataTable
      fixed-header
      :height="log_entries?.length > 10 ? 400 : null"
      :items="log_entries"
      :headers="headers"
      density="compact"
      :method="getData"
      :pagination="pagination"
    >
      <template #item.object_repr="{ item }">
        {{ substring(item.value.object_repr, 40) }}
      </template>
      <template #item.action_flag="{ item }">
        <v-chip v-if="item.value.action_flag == 1" color="indigo">
          {{ $t("permission.add") }}
          <template v-slot:append>
            <v-icon color="indigo">mdi-circle-medium</v-icon>
          </template>
        </v-chip>
        <v-chip v-else-if="item.value.action_flag == 2" color="success">
          {{ $t("permission.change") }}
          <template v-slot:append>
            <v-icon color="success">mdi-circle-medium</v-icon>
          </template>
        </v-chip>
        <v-chip v-else-if="item.value.action_flag == 3" color="error">
          {{ $t("permission.delete") }}
          <template v-slot:append>
            <v-icon color="error">mdi-circle-medium</v-icon>
          </template>
        </v-chip>
      </template>
    </DataTable>
  </v-card>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData();
      await this.getUsersList();
      await this.getContentTypes();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      loading: false,
      log_entries: [],
      contentTypes: [],
      headers: [
        { title: this.$t("log-entries.user"), key: "user" },
        { title: this.$t("log-entries.content-type"), key: "content_type" },
        { title: this.$t("log-entries.record"), key: "object_repr" },
        { title: this.$t("log-entries.action-time"), key: "action_time" },
        { title: this.$t("log-entries.action"), key: "action_flag" },
      ],
      action_id: null,
      search: null,
      date: null,
      contentTypeId: null,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
   
  },
  methods: {
    ...mapActions({}),
    async getData(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/log-entry`, {
        params: {
          page: page,
          page_size:per_page,
          txt_search: search,
          order_by: ordering,
          action_id: this.action_id,
          content_type: this.contentTypeId,
          date: this.date,
        },
      })
        .then((response) => {
          this.loading = false;
          this.log_entries = response.data.results;
          this.pagination = response.data.pagination;
          this.itemsPerPage[4].value = this.pagination.count;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getUsersList() {
      await this.axios(`${this.base_url}userdata/user-data/`).then(
        (response) => {
          this.users_list = response.data;
        }
      );
    },
    async getContentTypes() {
      await this.axios(`${this.base_url}api/content-types`).then((response) => {
        this.contentTypes = response.data;
      });
    },
  },
  
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
tbody td {
  font-size: 11px;
}
</style>
